import { h } from "preact";
import { Link } from "preact-router";
import LinkedinSVG from "../svgs/LinkedinSVG.jsx";
import { CONFIG_NAV_AND_ROUTES } from "../../configs/navAndRoutes";
import style from "./footer.scss";

const Footer = () => {
  const onClickOnLink = () => {
    // if (typeof window !== undefined) {
      // window.scrollTo(0, 0);
    // }
  };

  return (
    <footer class={style.ft}>
      <div class={style.ft_i}>
        <div class={style.ft_logo}>
          <img
            width={600}
            height={392}
            src="/assets/logos/HGD-logo-white.png"
            alt="HGD Polymers Logo"
            loading="lazy"
          />
        </div>

        <ul class={style.ftlinks} role="list">
          {CONFIG_NAV_AND_ROUTES.map((it, ind) => {
            // let isNews = false;
            // let isKontakt = false;
            let specialClass = "";

            if (it[1] === "/news") specialClass = style.adjtabs1;
            if (it[1] === "/kontakt") specialClass = style.adjtabs2;

            // const hasChildren = Array.isArray(it[2]);
            // const hasLink = it[1] === typeof "string";
            return (
              <li key={it[0]} className={[style.lili + " " + specialClass]}>
                {it[1] === null ? (
                  <div
                  // name={it[0]}
                  //  href="*"
                  >
                    {it[0]}
                  </div>
                ) : (
                  <>
                    {it[1] !== "/" ? ( // mute home in footer link list
                      <Link onClick={() => onClickOnLink()} href={it[1]}>
                        {it[0]}
                      </Link>
                    ) : null}
                  </>
                )}

                {Array.isArray(it[2]) ? (
                  <ul
                  // style={{visibility: isHovering ? 'visible' : 'hidden'}}
                  // className={!isHovering ? style.invis : ''}
                  >
                    {it[2].map((itt) => (
                      <li key={itt[0]} class={style.lala}>
                        <Link onClick={() => onClickOnLink()} href={itt[1]}>
                          {itt[0]}
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : null}
              </li>
            );
          })}
          <li class={[style.liadr + " " + style.lili]}>
            <address>
              HGD Polymers GmbH <br />
              Unterlettenstrasse 11
              <br />
              CH-9443 Widnau
            </address>
            <div class={style.soclink}>
              <a href="https://www.linkedin.com/company/hgd-polymers/">
                <LinkedinSVG /> LinkedIn
              </a>
            </div>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
