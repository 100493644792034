import { h } from "preact";
import { Router } from "preact-router";

import Header from "./header";
import Home from "../routes/home";
import Footer from "./footer";
import Produkte from "../routes/produkte";
import BarlogPlastics from "../routes/produkte/barlogPlastics";
import Leistungen from "../routes/leistungen";
import Kontakt from "../routes/kontakt";
import Eurotec from "../routes/produkte/eurotec";
import Allod from "../routes/produkte/allod";
import GabrielChemie from "../routes/produkte/gabrielChemie";
import Coppex from "../routes/produkte/coppex";
import EngineeringCAE from "../routes/leistungen/engineering";
import UeberUns from "../routes/unternehmen/ueberuns";
import Distribution from "../routes/leistungen/distribution";
import Handel from "../routes/leistungen/handel";
import Schulungen from "../routes/leistungen/schulungen";
import AGB from "../routes/unternehmen/agb";
import Impressum from "../routes/unternehmen/impressum";
import News from "../routes/news";
import SwissPlasticExpo2023 from "../routes/news/swissPlasticExpo2023";
import { useEffect, useState } from "preact/hooks";
import NotFound from "../routes/404";
import Art20221221 from "../routes/news/art20221221";

const App = () => {
  const [hasIndex, setHasIndex] = useState("");

  /**
   * === WORKAROUND ===
   *
   * users accessing site with index.html suffix create duplicate element
   *
   * solution: check if 2 main elements present in DOM by class .remdup
   *
   * hide element with CSS / display: none
   *
   */

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.location.pathname.includes("index.html")) {
        setHasIndex("/index.html");
      }
      const duplicate = document.querySelectorAll(".remdup");
      if (duplicate.length === 2) {
        duplicate[1].classList.add("hide");
      }
    }
  });

  return (
    <div class="remdup">
      <Header />
      <Router>
        <Home path={hasIndex ? "/index.html" : "/"} />
        <Produkte path={hasIndex ? "/produkte/index.html" : "/produkte"} />

        <BarlogPlastics
          path={
            hasIndex
              ? "/produkte/barlog-plastics/index.html"
              : "/produkte/barlog-plastics"
          }
        />

        <Eurotec
          path={hasIndex ? "/produkte/eurotec/index.html" : "/produkte/eurotec"}
        />

        <Allod
          path={hasIndex ? "/produkte/allod/index.html" : "/produkte/allod"}
        />

        <Coppex
          path={hasIndex ? "/produkte/coppex/index.html" : "/produkte/coppex"}
        />

        <GabrielChemie
          path={
            hasIndex
              ? "/produkte/gabriel-chemie/index.html"
              : "/produkte/gabriel-chemie"
          }
        />

        <Leistungen
          path={hasIndex ? "/leistungen/index.html" : "/leistungen"}
        />

        <EngineeringCAE
          path={
            hasIndex
              ? "/leistungen/engineering-cae/index.html"
              : "/leistungen/engineering-cae"
          }
        />

        <Distribution
          path={
            hasIndex
              ? "/leistungen/kunststoff-distribution/index.html"
              : "/leistungen/kunststoff-distribution"
          }
        />

        <Handel
          path={
            hasIndex
              ? "/leistungen/kunststoff-handel/index.html"
              : "/leistungen/kunststoff-handel"
          }
        />

        <Schulungen
          path={
            hasIndex
              ? "/leistungen/schulungen/index.html"
              : "/leistungen/schulungen"
          }
        />

        <Kontakt path={hasIndex ? "/kontakt/index.html" : "/kontakt"} />

        <UeberUns path={hasIndex ? "/about/index.html" : "/about"} />

        <AGB path={hasIndex ? "/agb/index.html" : "/agb"} />

        <Impressum path={hasIndex ? "/impressum/index.html" : "/impressum"} />

        <News path={hasIndex ? "/news/index.html" : "/news"} />

        <SwissPlasticExpo2023
          path={
            hasIndex
              ? "/news/swiss-plastic-expo-2023/index.html"
              : "/news/swiss-plastic-expo-2023"
          }
        />

        <Art20221221
          path={
            hasIndex
              ? "/news/barlog-plastics-hgd-polymers-neuer-distributor-fuer-ch-und-fl/index.html"
              : "/news/barlog-plastics-hgd-polymers-neuer-distributor-fuer-ch-und-fl"
          }
        />

        {hasIndex ? null : <NotFound default />}
      </Router>

      <Footer />
    </div>
  );
};

export default App;
