export const CONFIG_NAV_AND_ROUTES = [
    ["Home", '/'],
    ["Produkte", "/produkte", [
        ["BARLOG Plastics", "/produkte/barlog-plastics"],
        ["EUROTEC", "/produkte/eurotec"],
        ["ALLOD", '/produkte/allod'],
        ["GABRIEL Chemie", '/produkte/gabriel-chemie'],
        ["COPPEX", '/produkte/coppex'],
        // ["Handelsprodukte", '/produkte/handelsproduktse']
    ]],
    ["Leistungen", "/leistungen", [
        ["Kunststoff Distribution", "/leistungen/kunststoff-distribution"],
        ["Kunststoff Handel", "/leistungen/kunststoff-handel"],
        ["Engineering & CAE", '/leistungen/engineering-cae'],
        ["Schulungen", "/leistungen/schulungen"]
    ]],
    ["Unternehmen", null, [
        ["Über uns", '/about'],
         ["AGB", '/agb'],
         ["Impressum", '/impressum']
    ]],
    ["News", '/news', 
   //  [        ["Swiss Plastic Expo 2023", '/news/swiss-plastic-expo-2023']    ]

],
    ["Kontakt", '/kontakt']
]