const eurotecImagesDat = [
    {
      alt: "PC/ABS TECOTEK, Gehäuse Bedienelemente",
      caption: "PC/ABS TECOTEK, Gehäuse Bedienelemente",
      sizes: "(max-width: 768px) 768px, 1440px",
      sources: {
        sfallback: ["1440", "961", "/assets/images/eurtotec/i1/eurotec1-1440w.jpg"],
        swebp: [
          ["768", "/assets/images/eurotec/i1/eurotec1-768w.webp"],
          ["1440", "/assets/images/eurotec/i1/eurotec1-1440w.webp"],
        ],
        sjpg: [
          ["768", "/assets/images/eurotec/i1/eurotec1-768w.jpg"],
          ["1440", "/assets/images/eurotec/i1/eurotec1-1440w.jpg"],
        ],
      },
      canEnlarge: true,
    },
    {
        alt: "PA66/6 TECOMID, Gehäuse Reinigungsgerät",
        caption: "PA66/6 TECOMID, Gehäuse Reinigungsgerät",
        sizes: "(max-width: 768px) 768px, 1440px",
        sources: {
          sfallback: ["1440", "961", "/assets/images/eurtotec/i2/eurotec2-1440w.jpg"],
          swebp: [
            ["768", "/assets/images/eurotec/i2/eurotec2-768w.webp"],
            ["1440", "/assets/images/eurotec/i2/eurotec2-1440w.webp"],
          ],
          sjpg: [
            ["768", "/assets/images/eurotec/i2/eurotec2-768w.jpg"],
            ["1440", "/assets/images/eurotec/i2/eurotec2-1440w.jpg"],
          ],
        },
        canEnlarge: true,
      },
      {
        alt: "PP TECOLEN, Halterung Elektronik Automotive",
        caption: "PP TECOLEN, Halterung Elektronik Automotive",
        sizes: "(max-width: 768px) 768px, 1440px",
        sources: {
          sfallback: ["1440", "961", "/assets/images/eurtotec/i3/eurotec3-1440w.jpg"],
          swebp: [
            ["768", "/assets/images/eurotec/i3/eurotec3-768w.webp"],
            ["1440", "/assets/images/eurotec/i3/eurotec3-1440w.webp"],
          ],
          sjpg: [
            ["768", "/assets/images/eurotec/i3/eurotec3-768w.jpg"],
            ["1440", "/assets/images/eurotec/i3/eurotec3-1440w.jpg"],
          ],
        },
        canEnlarge: true,
      },
      {
        alt: "PPO TECOTEK, Motor Abdeckung Fräsmaschine",
        caption: "PPO TECOTEK, Motor Abdeckung Fräsmaschine",
        sizes: "(max-width: 768px) 768px, 1440px",
        sources: {
          sfallback: ["1440", "961", "/assets/images/eurtotec/i4/eurotec4-1440w.jpg"],
          swebp: [
            ["768", "/assets/images/eurotec/i4/eurotec4-768w.webp"],
            ["1440", "/assets/images/eurotec/i4/eurotec4-1440w.webp"],
          ],
          sjpg: [
            ["768", "/assets/images/eurotec/i4/eurotec4-768w.jpg"],
            ["1440", "/assets/images/eurotec/i4/eurotec4-1440w.jpg"],
          ],
        },
        canEnlarge: true,
      }
  ];
  
  export default eurotecImagesDat;
  