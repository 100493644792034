const allodImagesDat = [
    {
      alt: "Elektronikumspritzung mit TPE ALLRUNA",
      caption: "Elektronikumspritzung mit TPE ALLRUNA",
      sizes: "(max-width: 768px) 768px, 1440px",
      sources: {
        sfallback: ["1440", "1114", "/assets/images/allod/i1/allod1-1440w.jpg"],
        swebp: [
          ["768", "/assets/images/allod/i1/allod1-768w.webp"],
          ["1440", "/assets/images/allod/i1/allod1-1440w.webp"],
        ],
        sjpg: [
          ["768", "/assets/images/allod/i1/allod1-768w.jpg"],
          ["1440", "/assets/images/allod/i1/allod1-1440w.jpg"],
        ],
      },
      canEnlarge: true,
    },
    {
      alt: "Lüfterstellrad im Automobilinnenraum",
      caption: "Lüfterstellrad im Automobilinnenraum",
      sizes: "(max-width: 768px) 768px, 768px",
      sources: {
        sfallback: ["768", "496", "/assets/images/allod/i2/allod2-768w.jpg"],
        swebp: [
          ["768", "/assets/images/allod/i2/allod2-768w.webp"],
        ],
        sjpg: [
          ["768", "/assets/images/allod/i2/allod2-768w.jpg"],
        ],
      },
      canEnlarge: false,
    },
    {
        alt: "Gesichtsmaske",
        caption: "Gesichtsmaske",
        sizes: "(max-width: 547px) 547px, 547px",
        sources: {
          sfallback: ["547", "411", "/assets/images/allod/i3/allod3-547w.jpg"],
          swebp: [
            ["547", "/assets/images/allod/i3/allod3-547w.webp"],
          ],
          sjpg: [
            ["547", "/assets/images/allod/i3/allod3-547w.jpg"],
          ],
        },
        canEnlarge: false,
      },
      {
        alt: "TPE ALLRUNA Einfärbungen",
        caption: "TPE ALLRUNA Einfärbungen",
        sizes: "(max-width: 768px) 768px, 768px",
        sources: {
          sfallback: ["768", "1156", "/assets/images/allod/i4/allod4-768w.jpg"],
          swebp: [
            ["768", "/assets/images/allod/i4/allod4-768w.webp"],
          ],
          sjpg: [
            ["768", "/assets/images/allod/i4/allod4-768w.jpg"],
          ],
        },
        canEnlarge: false,
      },
      {
        alt: "Faltenbalg für Kabeldurchführungen Automotive",
        caption: "Faltenbalg für Kabeldurchführungen Automotive",
        sizes: "(max-width: 768px) 768px, 768px",
        sources: {
          sfallback: ["768", "537", "/assets/images/allod/i5/allod5-768w.jpg"],
          swebp: [
            ["768", "/assets/images/allod/i5/allod5-768w.webp"],
          ],
          sjpg: [
            ["768", "/assets/images/allod/i5/allod5-768w.jpg"],
          ],
        },
        canEnlarge: false,
      },
      {
        alt: "TPE ALLRUNA eingefärbt",
        caption: "TPE ALLRUNA eingefärbt",
        sizes: "(max-width: 768px) 768px, 768px",
        sources: {
          sfallback: ["768", "1156", "/assets/images/allod/i6/allod6-768w.jpg"],
          swebp: [
            ["768", "/assets/images/allod/i6/allod6-768w.webp"],
          ],
          sjpg: [
            ["768", "/assets/images/allod/i6/allod6-768w.jpg"],
          ],
        },
        canEnlarge: false,
      },
  ];
  
  export default allodImagesDat;
  