import style from './imageSmall.scss';

const ImageSmall = ({ imageConfig }) => {
    const { sizes, alt, sources } = imageConfig;
    const { sfallback, swebp, sjpg } = sources;
    return (
        <figure
        itemprop="image"
        itemscope=""
        itemtype="https://schema.org/ImageObject"
        class={style.ism_fi}
      >
        <meta itemprop="url contentUrl" content={sfallback[2]} />
        <meta itemprop="width" content={sfallback[0]} />
        <meta itemprop="height" content={sfallback[1]} />

        <picture>
          <source
            type="image/webp"
            srcset={swebp.map((it) => `${it[1]} ${it[0]}w`)}
            sizes={sizes}
          />
          <source
            type="image/jpeg"
            srcset={sjpg.map((it) => `${it[1]} ${it[0]}w`)}
            sizes={sizes}
          />
          <img
            class=""
            src={sfallback[2]}
            alt={alt}
            loading="lazy"
            width={sfallback[0]}
            height={sfallback[1]}
          />
        </picture>
      </figure>
    )
}

export default ImageSmall;
