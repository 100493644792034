import './style';
import './style/animations.css';
import '@fontsource/nunito/400.css';
import '@fontsource/nunito/500.css';
import '@fontsource/nunito/600.css';
import '@fontsource/nunito/700.css';

import '@fontsource/nunito/800.css';
import '@fontsource/nunito/900.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import App from './components/app';


export default App;
