import { useEffect } from "preact/hooks";
import "../../../style/bgimgs.scss";
import "../../../style/bgAnimations.css";
import style from "../prodStyle.scss";
import homeStyle from "../../home/style.scss";
import ImageMarkup from "../../../components/imageMarkup/ImageMarkup";
import allodImagesDat from "./allodImagesDat";

const Allod = () => {
  useEffect(() => {
    if (typeof "window" !== undefined) {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <>
      <main>
        <div class={style.pp}>
          <meta itemprop="datePublished" content="2022-12-13T08:00:00+08:00" />
          <meta itemprop="dateModified" content="2022-12-13T08:00:00+08:00" />
          <meta itemprop="lastReviewed" content="2022-12-13T08:00:00+08:00" />
          <span itemprop="primaryImageOfPage" itemscope="">
            <link
              itemprop="url"
              href="/assets/images/allod/i4/allod4-ls-1440w.jpg"
            />
          </span>

          <div class={style.hdr_fr}>
            <h1 itemprop="name">ALLOD</h1>
            <div class="headerimage bgsm revbgsm" />
          </div>

          <section class={style.pp_sec}>
            <div class={style.pp_left}>
              <div class={style.pp_log}>
                <img
                  width={600}
                  height={338}
                  src="/assets/logos/allod-logo.png"
                  alt="ALLOD Logo"
                  loading="lazy"
                />
              </div>

              <p class={style.pp_p} itemprop="description">
                ALLOD versteht sich als Entwicklungspartner für
                massgeschneiderte thermoplastische Compounds für die
                Kunststoffindustrie. Kerngeschäft ist die Entwicklung,
                Herstellung und Vertrieb thermoplastischer Elastomere (TPE)
                unter dem Markennamen ALLRUNA®.{" "}
              </p>

              <p class={style.pp_p}>
                Die Firma ALLOD ist Lieferant für zahlreiche Branchen, u.a.
                Automobil, Haushaltsgeräte, Elektro- und Elektronikanwendungen,
                Medizintechnik, Spielzeug, Lebensmittelanwendungen und
                Möbelindustrie.{" "}
              </p>

              <a
                itemprop="significantLink"
                class={style.pp_a}
                target="_blank"
                href="https://allod.com/"
              >
                ZUR WEBSEITE
              </a>

              <div class={style.pp_dvdr} />

              <div class={style.pp_adr}>
                <p class={style.pp_adr_t}>ALLOD Werkstoff GmbH & Co. KG</p>
                <p>Steinacher Strasse 3</p>
                <p>91593 Burgbernheim</p>
                <p>Deutschland</p>
              </div>
            </div>

            <div class={style.pp_right}>
              <div class={style.pp_listblock}>
                <h2 class={style.subhdr}>
                  ALLOD setzt vor allem auf hochspezialisierte Compounds und
                  Neuentwicklungen u. a. aus den folgenden Bereichen:
                </h2>

                <ul class={style.pp_stdlist}>
                  <li>
                    elektrisch hochleitfähige, flexible Materialien (Widerstände
                    bis zu 4 Ohm cm),
                  </li>
                  <li>thermisch leitfähige Compounds,</li>
                  <li>flammgeschützte Compounds,</li>
                  <li>Kunststoffe für den Kontakt mit Lebensmitteln,</li>
                  <li>weiche Materialien für die additive Fertigung,</li>
                  <li>chemische Beständigkeit,</li>
                  <li>hochtemperaturbeständige Compounds,</li>
                  <li>Mischungen mit niedrigem Druckverformungsrest (DVR),</li>
                  <li>
                    Haftungsmodifikation für eine grosse Bandbreite an
                    Kunststoffen.
                  </li>
                </ul>
              </div>

              <div class={style.imgblock}>
                {allodImagesDat.map((it) => (
                  <ImageMarkup imageConfig={it} />
                ))}
              </div>
            </div>
          </section>

          <section class="headerimage4 nopad">
            <div class={style.pp_fr1024}>
              <div class={[homeStyle.cardouter + " " + homeStyle.inprod]}>
                <div class={homeStyle.cardfr}>
                  <h2>DER SCHNELLSTE WEG ZUR BESTEN BETREUUNG!</h2>
                </div>
                <div class={homeStyle.clfl}>
                  <a href="tel:+41714404444">+41 (0) 71 440 44 44</a>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};

export default Allod;
