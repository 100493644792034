const coppexImagesDat = [
    {
      alt: "COPPEX Reinigungsgranulat",
      caption: "COPPEX Reinigungsgranulat",
      sizes: "(max-width: 768px) 768px, 768px",
      sources: {
        sfallback: ["768", "590", "/assets/images/coppex/i1/coppex1-768w.jpg"],
        swebp: [
          ["768", "/assets/images/coppex/i1/coppex1-768w.webp"],
        ],
        sjpg: [
          ["768", "/assets/images/coppex/i1/coppex1-768w.jpg"],
        ],
      },
      canEnlarge: false,
    },
    {
      alt: "COPPEX Reinigungsgranulat, Einfache Anwendung",
      caption: "COPPEX Reinigungsgranulat, Einfache Anwendung",
      sizes: "(max-width: 768px) 768px, 768px",
      sources: {
        sfallback: ["768", "590", "/assets/images/coppex/i2/coppex2-768w.jpg"],
        swebp: [
          ["768", "/assets/images/coppex/i2/coppex2-768w.webp"],
        ],
        sjpg: [
          ["768", "/assets/images/coppex/i2/coppex2-768w.jpg"],
        ],
      },
      canEnlarge: false,
    },
    {
      alt: "COPPEX Reinigungsgranulat, Wiederverschliessbare Gebinde 26 kg",
      caption: "COPPEX Reinigungsgranulat, Wiederverschliessbare Gebinde 26 kg",
      sizes: "(max-width: 768px) 768px, 768px",
      sources: {
        sfallback: ["768", "590", "/assets/images/coppex/i3/coppex3-768w.jpg"],
        swebp: [
          ["768", "/assets/images/coppex/i3/coppex3-768w.webp"],
        ],
        sjpg: [
          ["768", "/assets/images/coppex/i3/coppex3-768w.jpg"],
        ],
      },
      canEnlarge: false,
    }
  ];
  
  export default coppexImagesDat;
  