import { h } from "preact";
import { Link } from "preact-router/match";
import { getCurrentUrl } from "preact-router";
import { CONFIG_NAV_AND_ROUTES } from "../../configs/navAndRoutes";
// import style from "./style.scss";
import style from "./style.scss";
import { useEffect, useRef, useState } from "preact/hooks";
import useWindowDimensions from "../../utils/useWindowDimenstions";
import TriangleSVG from "./TriangleSVG";

// const maxNavBarItems = 5;

const NavMenu = ({ onDoNavigate }) => {
  // onDoNavigate: close menu if lower than desktop screen
  const [isHovering, setIsHovering] = useState(false);
  const navBarRef = useRef(null);
  // const activeNavMemoryRef = useRef(null);
  // const lastHoverRef = useRef(null);
  const { width } = useWindowDimensions();
  const currenUrl = getCurrentUrl();

  const onClickOnLink = (e) => {
    // setClickPing((prev) => prev + 1);

    onDoNavigate();
    e.target.blur();
  };

  // HOVERING
  const onHoverMouseEnter = (e) => {
    // if (width < 1024) return;
    // const animationTime = 270;
    // const timeSinceLastHover = Date.now() - lastHoverRef.current;
    // if (timeSinceLastHover < 50) return;

    const elTarget = e.target;
    // const navOrderNow = parseInt(elTarget.dataset.navorder, 10);
    // const orderDiff = navOrderNow - activeNavMemoryRef.current;
    // const elm = navBarRef.current.querySelector(
    //   `[data-navul="${navOrderNow}"]`
    // );

   //  let currentHeight = 0;


    /*
    if (elm && width > 1023) {
      currentHeight = elm.getBoundingClientRect().height;
      elm.classList.add("torev");

      setTimeout(() => {
        elm.classList.remove("torev");
        // console.log("ELE__", currentHeight);
      }, animationTime);
    }

    // left to right
    if (orderDiff === 1 && width > 1023) {
      // console.log("____ddd");
      const el = navBarRef.current.querySelector(
        `[data-navul="${activeNavMemoryRef.current}"]`
      );
      if (el) {
        el.classList.add("toleft");
        el.style.height = `${currentHeight}px`;

        setTimeout(() => {
          el.classList.remove("toleft");
          el.style.height = "unset";
        }, animationTime);
      }
    }

    // to right
    if (orderDiff === -1 && width > 1023) {
      // console.log('____ddd')
      const el = navBarRef.current.querySelector(
        `[data-navul="${activeNavMemoryRef.current}"]`
      );
      if (el) {
        el.classList.add("toright");
        el.style.height = `${currentHeight}px`;
        setTimeout(() => {
          el.classList.remove("toright");
          el.style.height = "unset";
        }, animationTime);
      }
    }

    */

    /// activeNavMemoryRef.current = navOrderNow;
    // lastHoverRef.current = Date.now();


    setIsHovering(true);
  };

  const onHoverMouseLeave = (e) => {
    setIsHovering(false);
    // e.target.blur();
    
  };

  // LINK MARKERS
  useEffect(() => {
    const navMarksDOM = navBarRef.current.querySelectorAll("[data-men]");
    // if (!navMarksDOM) return;

    navMarksDOM.forEach((it) => {
      const nodeType = it.dataset.men;
      const nodeUrl = it.pathname;
      const parentIndex = it.dataset.child;
      const getParentElement = navBarRef.current.querySelector(
        `[data-parent="${parentIndex}"]`
      );





      if (nodeUrl === currenUrl || nodeUrl + '/' === currenUrl) {
        // console.log("did match", nodeUrl, currenUrl);

        // is top navigation
        if (nodeType === "t") {
          it.classList.add("nav_mark");
        } else if (nodeType === "s") {
          it.classList.add("active");

          if (width > 1023) {
            if (getParentElement) getParentElement.classList.add("nav_mark");
          } else {
            if (getParentElement) {
             // console.log("par____", getParentElement);
              if (getParentElement.classList.contains("nav_mark")) {
                getParentElement.classList.remove("nav_mark");
              }
            }
          }
        }
      } else {
        // remove marker class
        if (nodeType === "t") {
          if (it.classList.contains("nav_mark")) {
            it.classList.remove("nav_mark");
          }
        } else if (nodeType === "s") {
          if (it.classList.contains("active")) {
            it.classList.remove("active");
            if (getParentElement.classList.contains('nav_mark')) {
              getParentElement.classList.remove('nav_mark')
            }
          }
        }
      }

      // console.log(it.dataset.men, it.href, it.pathname)
    });


  }, [currenUrl, width]);

  return (
    <ul ref={navBarRef} class={style.nm}>
      {CONFIG_NAV_AND_ROUTES.map((it, ind) => {
        const hasChildren = Array.isArray(it[2]);
        // const hasLink = it[1] === typeof "string";

        let speciaClass = "";

        if (it[1] === "/news") speciaClass = style.adjtab3;
        if (it[1] === "/kontakt") speciaClass = style.adjtab4;

        return (
          <li
            onMouseEnter={onHoverMouseEnter}
            onMouseLeave={onHoverMouseLeave}
            // onBlur={onHoverMouseLeave}
            onFocus={onHoverMouseEnter}
            key={it[0]}
            aria-haspopup={hasChildren ? "true" : "false"}
            data-navorder={ind}
            class={[speciaClass + " " + style.limain + " revtoplink"]}
            // class={it[1] === '/' ? 'nav_mark' : ''}
          >
            {it[1] === null ? (
              <button class={style.nav_nolink} data-parent={ind}>
                {it[0]}
                {Array.isArray(it[2]) ? <TriangleSVG /> : null}
              </button>
            ) : (
              <Link
                class={style.nav_toplink}
                // activeClassName="nav_mark"
                href={it[1]}
                onClick={(e) => onClickOnLink(e)}
                data-men="t"
                data-parent={ind}
              >
                {it[0]}
                {Array.isArray(it[2]) ? <TriangleSVG /> : null}
              </Link>
            )}

            {Array.isArray(it[2]) ? (
              <ul
                class="revtopmen"
                data-navul={ind}
                style={{
                  // visibility: isHovering || width < 1024 ? "visible" : "hidden",
                }}
                // class={!isHovering ? "invis" : ""}
              >
                {it[2].map((itt) => (
                  <li key={itt[0]}>
                    <Link
                      // activeClassName="active"
                      //                 activeClassName="nav_mark"

                      href={itt[1]}
                      onClick={(e) => onClickOnLink(e)}
                      data-men="s"
                      data-child={ind}
                    >
                      {itt[0]}
                    </Link>
                  </li>
                ))}
              </ul>
            ) : null}
          </li>
        );
      })}
    </ul>
  );
};

export default NavMenu;
