const TradeSVG = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 250 250"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
  >
    <g transform="matrix(1,0,0,1,-3041.7,-75)">
      <g transform="matrix(0.629201,0,0,0.629201,2766.5,74.9919)">
        <rect
          id="Trade"
          x="437.366"
          y="0.013"
          width="397.33"
          height="397.33"
          style="fill:none;"
        />
        <g id="Trade1">
          <g transform="matrix(1.58932,0,0,1.58932,-2373.5,-119.186)">
            <path d="M1909.64,239.95L1788.58,239.95C1786.38,239.95 1784.58,238.158 1784.58,235.95C1784.58,233.742 1786.38,231.95 1788.58,231.95L1908.86,231.95L1853.76,176.852C1852.2,175.291 1852.2,172.756 1853.76,171.195C1855.32,169.634 1857.86,169.634 1859.42,171.195L1921.74,233.514C1923.3,235.076 1923.3,237.609 1921.74,239.171L1859.67,301.24C1858.11,302.801 1855.57,302.801 1854.01,301.24C1852.45,299.679 1852.45,297.144 1854.01,295.583L1909.64,239.95ZM1878.34,168.05L1933.44,223.148C1935,224.709 1935,227.244 1933.44,228.805C1931.88,230.366 1929.35,230.366 1927.79,228.805L1865.47,166.486C1863.9,164.924 1863.9,162.391 1865.47,160.829L1927.54,98.76C1929.1,97.199 1931.63,97.199 1933.19,98.76C1934.75,100.321 1934.75,102.856 1933.19,104.417L1877.56,160.05L1998.62,160.05C2000.83,160.05 2002.62,161.842 2002.62,164.05C2002.62,166.258 2000.83,168.05 1998.62,168.05L1878.34,168.05Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default TradeSVG;
