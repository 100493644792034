import "../../../style/bgimgs.scss";
import "../../../style/bgAnimations.css";
import style from "../../produkte/prodStyle.scss";
import uuStyle from "../ueberuns/uustyle.scss";
import { useEffect } from "preact/hooks";

const Impressum = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <>
      <main>
        <div class={uuStyle.uu}>
          <meta itemprop="datePublished" content="2022-12-13T08:00:00+08:00" />
          <meta itemprop="dateModified" content="2022-12-13T08:00:00+08:00" />
          <meta itemprop="lastReviewed" content="2022-12-13T08:00:00+08:00" />
          <span itemprop="primaryImageOfPage" itemscope="">
            <link itemprop="url" href="/assets/logos/logo600x600.png" />
          </span>
          <div class={style.hdr_fr}>
            <h1 itemprop="name">Impressum</h1>
            <div class="headerimage bgsm revbgsm" />
          </div>

          <section class={uuStyle.pltxt}>
            <div class={uuStyle.uu_fr}>
              <h2>Kontaktadresse</h2>
              <p>
                HGD Polymers GmbH
                <br />
                Unterlettenstrasse 11
                <br />
                CH-9443 Widnau
                <br />
                Phone +41 (0) 71 440 44 44
                <br />
                Mail info@hgd-polymers.ch
              </p>

              <h2>Vertretungsberechtigte Personen</h2>
              <p>Halil Sevimli; Geschäftsführer</p>

              <h2>Handelsregistereintrag</h2>

              <p>
                Eingetragener Firmenname: HGD Polymers GmbH
                <br />
                Nummer: CH-320.4.094.814-8
                <br />
                Handelsregisteramt: St.Gallen
              </p>

              <h2>Mehrwehrtsteuernummer</h2>
              <p>CHE-353.226.171</p>

              <h2>Haftungsausschluss</h2>

              <p>
                Der Autor übernimmt keinerlei Gewähr hinsichtlich der
                inhaltlichen Richtigkeit, Genauigkeit, Aktualität,
                Zuverlässigkeit und Vollständigkeit der Informationen.
              </p>
              <p>
                Haftungsansprüche gegen den Autor wegen Schäden materieller oder
                immaterieller Art, welche aus dem Zugriff oder der Nutzung bzw.
                Nichtnutzung der veröffentlichten Informationen, durch
                Missbrauch der Verbindung oder durch technische Störungen
                entstanden sind, werden ausgeschlossen.
              </p>

              <p>
                Alle Angebote sind unverbindlich. Der Autor behält es sich
                ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne
                gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen
                oder die Veröffentlichung zeitweise oder endgültig einzustellen.
              </p>

              <h2>Haftung für Links</h2>

              <p>
                Verweise und Links auf Webseiten Dritter liegen ausserhalb
                unseres Verantwortungsbereichs. Es wird jegliche Verantwortung
                für solche Webseiten abgelehnt. Der Zugriff und die Nutzung
                solcher Webseiten erfolgen auf eigene Gefahr des Nutzers oder
                der Nutzerin.
              </p>

              <h2>Datenschutz</h2>

              <p>
                Gestützt auf Artikel 13 der schweizerischen Bundesverfassung und
                die datenschutzrechtlichen Bestimmungen des Bundes
                (Datenschutzgesetz, DSG) hat jede Person Anspruch auf Schutz
                ihrer Privatsphäre sowie auf Schutz vor Missbrauch ihrer
                persönlichen Daten. Wir halten diese Bestimmungen ein.
                Persönliche Daten werden streng vertraulich behandelt und weder
                an Dritte verkauft noch weiter gegeben.
              </p>

              <p>
                In enger Zusammenarbeit mit unseren Hosting-Providern bemühen
                wir uns, die Datenbanken so gut wie möglich vor fremden
                Zugriffen, Verlusten, Missbrauch oder vor Fälschung zu schützen.
              </p>

              <p>
                Beim Zugriff auf unsere Webseiten werden folgende Daten in
                Logfiles gespeichert: IP-Adresse, Datum, Uhrzeit,
                Browser-Anfrage und allg. übertragene Informationen zum
                Betriebssystem resp. Browser. Diese Nutzungsdaten bilden die
                Basis für statistische, anonyme Auswertungen, so dass Trends
                erkennbar sind, anhand derer wir unsere Angebote entsprechend
                verbessern können.
              </p>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};

export default Impressum;
