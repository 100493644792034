import { h } from "preact";
import { useState } from "preact/hooks";
import { Link } from 'preact-router'
import NavMenu from "../navmenu/NavMenu";
import CloseSymbSVG from "./CloseSymbSVG";
import HamburgerMenuSVG from "./HamburgerMenuSVG";
import style from "./header.scss";
import useWindowDimensions from "../../utils/useWindowDimenstions";

const Header = () => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const { width } = useWindowDimensions();
// console.log('outreee', getCurrentUrl())

  const onToggleMenuOpen = () => {
    if (width > 1023) return;
    const doc = document.documentElement;
    if (menuIsOpen) {
      if (doc.classList.contains(style.nooverflow)) {
        doc.classList.remove(style.nooverflow);
      }
    } else {
      doc.classList.add(style.nooverflow);
    }
    setMenuIsOpen((prev) => !prev);
  };

  



  return (
    <header class={[style.hdr + (menuIsOpen ? " " + style.isopen : "")]}>
      <div class={style.hdr_i}>
        <div class={style.log}>
          <Link href="/">
          <img loading="lazy" width={600} height={393} src="/assets/logos/HGD-logo.png" alt="HGD Polymers Logo" />
          </Link>
          </div>
        <nav 
         // role="navigation" 
         class={style.nvm}
         id="main-menu"
         >
          <NavMenu onDoNavigate={() => onToggleMenuOpen()} />
        </nav>
        <button
          className={style.mnbtn}
          type="button"
          onClick={() => onToggleMenuOpen()}
          aria-expanded={menuIsOpen}
          aria-controls="main-menu"
          aria-label="Hauptmenu"
        >
          {menuIsOpen ? <CloseSymbSVG /> : <HamburgerMenuSVG />}
          
        </button>
      </div>
    </header>
  );
};

export default Header;
