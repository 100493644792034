import { Link } from "preact-router/match";
import DistributionSVG from "../../../components/svgs/DistributionSVG";
import EngineeringSVG from "../../../components/svgs/EngineeringSVG";
import ImageMarkup from "../../../components/imageMarkup/ImageMarkup";
import style from "../styleSingleService.scss";
import "../../../style/bgimgs.scss";
import style2 from "../../produkte/prodStyle.scss";
import homeStyle from "../../home/style.scss";
import TradeSVG from "../../../components/svgs/TradeSVG";
import EducationSVG from "../../../components/svgs/EducationSVG";
import { useEffect } from "preact/hooks";

const EngineeringCAE = () => {
  useEffect(() => {
    if (typeof "window" !== undefined) {
      window.scrollTo(0, 0);
    }
  }, []);
  return (
    <>
      <main>
        <div>
          <meta itemprop="datePublished" content="2022-12-13T08:00:00+08:00" />
          <meta itemprop="dateModified" content="2022-12-13T08:00:00+08:00" />
          <meta itemprop="lastReviewed" content="2022-12-13T08:00:00+08:00" />
          <span itemprop="primaryImageOfPage" itemscope="">
            <link
              itemprop="url"
              href="https://www.hgd-polymers.ch/assets/images/etc/engineering/bgcard3-ai-1440w.jpg"
            />
          </span>
          <div class={style.ss_bgitopfr}>
            <div class={style.ss_bg1} />
            <div class={style.ss_bgitopinner}>
              <EngineeringSVG />
              <h1 itemprop="name">Engineering & CAE</h1>
            </div>
          </div>
          <section>
            <div class={style.ss_sec}>
              <div class={style.ss_lead} itemprop="description">
                Durch die Zusammenarbeit mit unserem Partner BARLOG Plastics
                beschleunigen Sie Ihre Produktentwicklung und optimieren Ihre
                Produkte. Ob durch Virtual Molding oder FE- Strukturanalyse: Wir
                unterstützen Sie kompetent und zügig im virtuellen Raum - von
                der Idee bis zur Serienfertigung.
              </div>

              <div class={style.ss_mainc}>
                <h2>
                  Mit Virtual Molding und FE-Analysen schnell und sicher von der
                  Idee zur Serie
                </h2>

                <p>
                  Im permanenten „Time-to-Market“-Wettrennen werden
                  Produktlebenszyklen und damit auch Produktentwicklungszyklen
                  immer kürzer. Traditionelle Produktentwicklung stösst hier an
                  ihre Grenzen - oft verbunden mit teuren und langwierigen
                  Anpassungen in den späteren Projektphasen. Die Folge: nicht
                  eingehaltene Termine, ungeplante Kosten und unzufriedene
                  Kunden. Der Einsatz von CAE ermöglicht es einen Grossteil der
                  Produktentwicklung virtuell zu realisieren und deutlich
                  schneller zu besseren Produkten zu gelangen. Die Grossteil
                  Simulation erkennt früh neuralgische Stellen im Design und
                  ermöglicht so einen reduzierten Materialeinsatz und eine
                  belastungs- und werkzeuggerechte Konstruktion. Teure
                  Änderungsschleifen in einer späten Projektphase können so
                  verhindert werden.
                </p>
                <br />
                <br />
                <h2>Fehlerverhütung statt Fehlerentdeckung</h2>
                <p>
                  Wie deutlich die Kostenreduzierung ausfallen kann, zeigt sich
                  in der Zehnerregel der Fehlerkosten („Rule of Ten“). Sie
                  definiert, dass sich die Kosten für einen unentdeckten Fehler
                  pro Wertschöpfungsstufe um den Faktor 10 erhöhen. Das bedeutet
                  im Umkehrschluss: Eine durch Computer-Aided Engineering (CAE)
                  bestens unterstützte Entwicklungsphase spart Kosten, da Fehler
                  schneller in der Wertschöpfungskette entdeckt und beseitigt
                  werden können. Im Fokus steht immer die Fehlerverhütung und
                  nicht die Fehlerentdeckung im späteren Projektstadium.
                </p>
              </div>

              <ImageMarkup
                imageConfig={{
                  alt: "Zehnerregel der Fehlerkosten („Rule of Ten“)",
                  caption: "Zehnerregel der Fehlerkosten („Rule of Ten“)",
                  sources: {
                    sfallback: [
                      "1440",
                      "924",
                      "/assets/images/etc/engineering/chart/engineering_chart-1440w.jpg",
                    ],
                    swebp: [
                      [
                        "768",
                        "/assets/images/etc/engineering/chart/engineering_chart-768w.webp",
                      ],
                      [
                        "1440",
                        "/assets/images/etc/engineering/chart/engineering_chart-1440w.webp",
                      ],
                    ],
                    sjpg: [
                      [
                        "768",
                        "/assets/images/etc/engineering/chart/engineering_chart-768w.jpg",
                      ],
                      [
                        "1440",
                        "/assets/images/etc/engineering/chart/engineering_chart-1440w.jpg",
                      ],
                    ],
                  },
                  canEnlarge: true,
                }}
              />
            </div>
          </section>
          <br />
          <br />
          <br />
          <div class={style.ss_navblockfr}>
            <nav>
              <Link href="/leistungen/kunststoff-distribution">
                <DistributionSVG />
                <span>
                  KUNSTSTOFF <br /> DISTRIBUTION
                </span>
              </Link>

              <Link href="/leistungen/kunststoff-handel">
                <TradeSVG />
                <span>
                  KUNSTSTOFF <br /> HANDEL
                </span>
              </Link>

              <Link href="/leistungen/schulungen">
                <EducationSVG />
                <span>SCHULUNGEN</span>
              </Link>
            </nav>
          </div>

          <section class="headerimage4 nopad">
            <div class={style2.pp_fr1024}>
              <div class={[homeStyle.cardouter + " " + homeStyle.inprod]}>
                <div class={homeStyle.cardfr}>
                  <h2>DER SCHNELLSTE WEG ZUR BESTEN BETREUUNG!</h2>
                </div>
                <div class={homeStyle.clfl}>
                  <a href="tel:+41714404444">+41 (0) 71 440 44 44</a>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};

export default EngineeringCAE;
