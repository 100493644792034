import style from "./imageMarkup.scss";
import { useState, useRef } from "preact/hooks";
import useWindowDimensions from "../../utils/useWindowDimenstions";
import aniStyle from "./imageZoomAni.css";
import DoZoomSVG from "./DoZoomSVG";
import ExitZoomSVG from "./ExitZoomSVG";

const ImageMarkup = ({ imageConfig }) => {
  const { width } = useWindowDimensions();

  const [isOpen, setIsOpen] = useState(false);
  const imageRef = useRef();
  const captionRef = useRef();

  const onToogleZoom = () => {
    setIsOpen((prev) => !prev);
    // console.log(captionRef.current.getBoundingClientRect().height);
  };

  const {
    alt,
    caption,
    sizes,
    sources: { sfallback, swebp, sjpg },
    canEnlarge,
  } = imageConfig;

  return (
    <figure
      itemprop="image"
      itemscope=""
      itemtype="https://schema.org/ImageObject"
      class={style.i_f}
      style={
        isOpen
          ? {
              height: parseInt(
                imageRef.current.height +
                  1 +
                  captionRef.current.getBoundingClientRect().height,
                10
              ),
            }
          : ""
      }
    >
      <meta itemprop="url contentUrl" content={sfallback[2]} />
      <meta itemprop="width" content={sfallback[0]} />
      <meta itemprop="height" content={sfallback[1]} />

      {canEnlarge && width > 1023 ? (
        <div class={style.i_bf}>
          <button
            aria-label={isOpen ? "verleinern" : "vergrössern"}
            style={
              isOpen ? { zIndex: 6, cursor: "zoom-out" } : { cursor: "zoom-in" }
            }
            type="button"
            onClick={() => onToogleZoom()}
            onBlur={() => setIsOpen(false)}
          >
            {isOpen ? <ExitZoomSVG /> : <DoZoomSVG />}
          </button>
        </div>
      ) : null}

      <picture>
        <source
          type="image/webp"
          srcset={swebp.map((it) => `${it[1]} ${it[0]}w`)}
          // srcset="/system/cms/files/files/000/010/134/original/Butter_1040x585-1.webp 1040w, /system/cms/files/files/000/010/325/original/Butter_1920x1080-1.webp 1920w"
          sizes={sizes}
        />
        <source
          type="image/jpeg"
          // srcset="/system/cms/files/files/000/010/133/original/Butter_1040x585-1.jpg 1040w, /system/cms/files/files/000/010/325/original/Butter_1920x1080-1.jpg 1920w"
          srcset={sjpg.map((it) => `${it[1]} ${it[0]}w`)}
          sizes={sizes}
        />
        <img
          class={
            isOpen && width > 1023
              ? style.onzoom + " " + aniStyle.zoomani
              : aniStyle.zoomdisap
          }
          ref={imageRef}
          src={sfallback[2]}
          alt={alt}
          loading="lazy"
          width={sfallback[0]}
          height={sfallback[1]}
        />
      </picture>
      {!isOpen ? (
        <figcaption ref={captionRef} itemprop="description">
          {caption}
        </figcaption>
      ) : null}
    </figure>
  );
};

export default ImageMarkup;
