import { Link } from "preact-router/match";
import DistributionSVG from "../../../components/svgs/DistributionSVG";
import style from "../styleSingleService.scss";
import "../../../style/bgimgs.scss";
import style2 from "../../produkte/prodStyle.scss";
import homeStyle from "../../home/style.scss";
import TradeSVG from "../../../components/svgs/TradeSVG";
import EducationSVG from "../../../components/svgs/EducationSVG";
import EngineeringSVG from "../../../components/svgs/EngineeringSVG";
import { useEffect } from "preact/hooks";

const Distribution = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
  }, []);
  return (
    <>
      <main>
        <div>
          <meta itemprop="datePublished" content="2022-12-13T08:00:00+08:00" />
          <meta itemprop="dateModified" content="2022-12-13T08:00:00+08:00" />
          <meta itemprop="lastReviewed" content="2022-12-13T08:00:00+08:00" />
          <span itemprop="primaryImageOfPage" itemscope="">
            <link
              itemprop="url"
              href="https://www.hgd-polymers.ch/assets/images/etc/distribution/bgcard1-ai-1440w.jpg"
            />
          </span>
          <div class={style.ss_bgitopfr}>
            <div class={style.ss_bg2} />
            <div class={style.ss_bgitopinner}>
              <DistributionSVG />
              <h1 itemprop="name">Kunststoff Distribution</h1>
            </div>
          </div>
          <section>
            <div class={style.ss_sec}>
              <div class={style.ss_lead} itemprop="description">
                In Zusammenarbeit mit unserem Logistikpartner machen wir
                unmögliches möglich.
              </div>

              <div class={style.ss_mainc}>
                <h2>Distributionspartner von führenden Herstellern</h2>
                <p>
                  HGD Polymers vertreibt eine grosse Bandbreite an technischen
                  Kunststoffgranulaten für den Einsatz in verschiedenen
                  führenden Industrien. Unser Portfolio umfasst alle Materialien
                  von PP (Polypropylen), PA (Polyamid), POM (Polyacetal), PBT
                  (Polybutylenterephthalat) bis hin zu speziellen
                  Hochleistungspolymeren wie PK (Polyketone), PPA
                  (Polyphthalamide), PPS (Polyphenylensulfid) und LCP (Liquid
                  Crystalline Polymer). Um sicherzustellen, dass wir immer den
                  Bedürfnissen unserer Kunden gerecht werden, arbeiten wir eng
                  mit unseren Kunststoffproduzenten zusammen.
                </p>
                <p>
                  Das Distributionsportfolio für Kunststoffgranulate von HGD
                  Polymers umfasst Markenprodukte führender Hersteller wie
                  BARLOG Plastics, Eurotec, Allod, Gabriel Chemie und Semrau
                  Technik. Mit einer breiten Auswahl an hochwertigen
                  Kunststoffgranulaten für vielseitige Anwendungen beliefern wir
                  fast alle Branchen in der Schweiz.
                </p>
              </div>
            </div>
          </section>
          <br />
          <br />
          <br />
          <div class={style.ss_navblockfr}>
            <nav>
              <Link href="/leistungen/engineering-cae">
                <EngineeringSVG />
                <span>
                  ENGINEERING <br /> & CAE
                </span>
              </Link>

              <Link href="/leistungen/kunststoff-handel">
                <TradeSVG />
                <span>
                  KUNSTSTOFF <br /> HANDEL
                </span>
              </Link>

              <Link href="/leistungen/schulungen">
                <EducationSVG />
                <span>SCHULUNGEN</span>
              </Link>
            </nav>
          </div>

          <section class="headerimage4 nopad">
            <div class={style2.pp_fr1024}>
              <div class={[homeStyle.cardouter + " " + homeStyle.inprod]}>
                <div class={homeStyle.cardfr}>
                  <h2>DER SCHNELLSTE WEG ZUR BESTEN BETREUUNG!</h2>
                </div>
                <div class={homeStyle.clfl}>
                  <a href="tel:+41714404444">+41 (0) 71 440 44 44</a>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};

export default Distribution;
