import "../../../style/bgimgs.scss";
import "../../../style/bgAnimations.css";
import style from "../../produkte/prodStyle.scss";
import homeStyle from "../../home/style.scss";
import uuStyle from "./uustyle.scss";
import ImageMarkup from "../../../components/imageMarkup/ImageMarkup";
import LinkedinSVG from "../../../components/svgs/LinkedinSVG.jsx";
import { useEffect } from "preact/hooks";

const UeberUns = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <>
      <main>
        <div class={uuStyle.uu}>
          <meta itemprop="datePublished" content="2022-12-13T08:00:00+08:00" />
          <meta itemprop="dateModified" content="2022-12-13T08:00:00+08:00" />
          <meta itemprop="lastReviewed" content="2022-12-13T08:00:00+08:00" />
          <span itemprop="primaryImageOfPage" itemscope="">
            <link itemprop="url" href="/assets/logos/logo600x600.png" />
          </span>

          <div class={style.hdr_fr}>
            <h1 itemprop="name">Über uns</h1>
            <div class="headerimage bgsm revbgsm" />
          </div>
          <section>
            <div class={uuStyle.uu_fr}>
              <p itemprop="description">
                Über 20 Jahre Know-how in der Kunststoffindustrie — das ist es,
                wovon unsere Kunden am meisten profitieren.
              </p>

              <p>
                Dabei liegt uns eines besonders am Herzen: Gemeinsam mit Ihnen
                die beste technische Lösung für Ihr Kunststoff-Endprodukt zu
                finden.
              </p>
              <p>
                Damit Sie das denkbar beste Gefühl mit diesem Resultat haben,
                beziehen wir natürlich auch wirtschaftliche und qualitative
                Faktoren mit ein.
              </p>

              <p>
                Ihr Metier ist uns bestens vertraut, mit unserer Erfahrung im
                Kunststoffeinkauf, in der Kunststoffverarbeitung und der
                Anwendungstechnik sind uns Ihre Ansprüche an ein gutes Polymer
                bestens bekannt.
              </p>
              <p>
                Gerne sind wir Ihnen behilflich, stellen Sie uns auf die Probe.
              </p>
            </div>

            <div class={uuStyle.imgfr}>
              <ImageMarkup
                imageConfig={{
                  alt: "Portrait Halil Sevimli, HGD-Polymers GmbH",
                  hasCaption: false,
                  caption: (
                    <div>
                      {" "}
                      <span class={uuStyle.img_name}>Halil Sevimli</span>
                      <br />
                      <span class={uuStyle.img_position}>Geschäftsführer</span>
                      <br />
                      <span>
                        <a href="https://www.linkedin.com/in/halil-sevimli-123607164/">
                          <LinkedinSVG /> LinkedIn
                        </a>
                      </span>{" "}
                    </div>
                  ),
                  sizes: "(max-width: 768x) 768px, 768px",
                  sources: {
                    sfallback: [
                      "768",
                      "511",
                      "/assets/images/etc/about/halil-768w.jpg",
                    ],
                    swebp: [
                      ["768", "/assets/images/etc/about/halil-768w.webp"],
                    ],
                    sjpg: [["768", "/assets/images/etc/about/halil-768w.jpg"]],
                  },
                  canEnlarge: false,
                }}
              />
            </div>
          </section>
          <br />
          <br />
          <br />
          <section class="headerimage4 nopad">
            <div class={style.pp_fr1024}>
              <div class={[homeStyle.cardouter + " " + homeStyle.inprod]}>
                <div class={homeStyle.cardfr}>
                  <h2>DER SCHNELLSTE WEG ZUR BESTEN BETREUUNG!</h2>
                </div>
                <div class={homeStyle.clfl}>
                  <a href="tel:+41714404444">+41 (0) 71 440 44 44</a>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};

export default UeberUns;
