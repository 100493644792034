import { Link } from "preact-router/match";
import DistributionSVG from "../../../components/svgs/DistributionSVG";
import EngineeringSVG from "../../../components/svgs/EngineeringSVG";
import style from "../styleSingleService.scss";
import "../../../style/bgimgs.scss";
import style2 from "../../produkte/prodStyle.scss";
import homeStyle from "../../home/style.scss";
import TradeSVG from "../../../components/svgs/TradeSVG";
import EducationSVG from "../../../components/svgs/EducationSVG";
import { useEffect } from "preact/hooks";
import handelTableDat from "./handelTableDat.";

const Handel = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <>
      <main>
        <div>
          <meta itemprop="datePublished" content="2022-12-13T08:00:00+08:00" />
          <meta itemprop="dateModified" content="2022-12-13T08:00:00+08:00" />
          <meta itemprop="lastReviewed" content="2022-12-13T08:00:00+08:00" />
          <span itemprop="primaryImageOfPage" itemscope="">
            <link
              itemprop="url"
              href="https://www.hgd-polymers.ch/assets/images/etc/trade/bgcard1-a2-1440w.jpg"
            />
          </span>
          <div class={style.ss_bgitopfr}>
            <div class={style.ss_bg4} />
            <div class={style.ss_bgitopinner}>
              <TradeSVG />
              <h1 itemprop="name">Kunststoff Handel</h1>
            </div>
          </div>
          <section>
            <div class={style.ss_sec}>
              <div class={style.ss_lead} itemprop="description">
                Auch ausserhalb unser Lieferportfolios sind wir ihnen jederzeit
                behilflich und suchen gezielt nach Materialien, die sie
                wünschen.
              </div>

              <div class={style.ss_mainc}>
                <h2>Wer sucht, der findet!</h2>
                <p>
                  Wir stehen in ständigem Kontakt mit unseren Partnern auf der
                  ganzen Welt und können Ihnen fast jedes Produkt anbieten.
                </p>
                <p>
                  Das qualifizierte HGD Polymers Team nimmt sich Ihrer
                  spezifischen Anfragen an und offeriert Ihnen das Vorprodukt,
                  welches Ihre technischen Spezifikationen zu wettbewerbsfähigen
                  Preisen am besten abdeckt.
                </p>

                <br />
                <br />
                <table
                  style={{ "margin-left": "auto", "margin-right": "auto" }}
                  class={[style2.pp_table + " " + style2.pp_mgtop85]}
                >
                  <thead>
                    <tr>
                      {handelTableDat.headers.map((it) => (
                        <th key={it}>{it}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {handelTableDat.data.map((it, ind) => (
                      <tr
                        class={
                          (ind + 1) % 2
                            ? style2.pp_table_c1
                            : style2.pp_table_c2
                        }
                        key={it[1]}
                      >
                        {it.map((itt) => (
                          <td key={itt}>{itt}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </section>
          <br />
          <br />
          <br />
          <div class={style.ss_navblockfr}>
            <nav>
              <Link href="/leistungen/kunststoff-distribution">
                <DistributionSVG />
                <span>
                  KUNSTSTOFF <br /> DISTRIBUTION
                </span>
              </Link>
              <Link href="/leistungen/engineering-cae">
                <EngineeringSVG />
                <span>
                  ENGINEERING <br /> & CAE
                </span>
              </Link>
              <Link href="/leistungen/schulungen">
                <EducationSVG />
                <span>SCHULUNGEN</span>
              </Link>
            </nav>
          </div>

          <section class="headerimage4 nopad">
            <div class={style2.pp_fr1024}>
              <div class={[homeStyle.cardouter + " " + homeStyle.inprod]}>
                <div class={homeStyle.cardfr}>
                  <h2>DER SCHNELLSTE WEG ZUR BESTEN BETREUUNG!</h2>
                </div>
                <div class={homeStyle.clfl}>
                  <a href="tel:+41714404444">+41 (0) 71 440 44 44</a>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};

export default Handel;
