
import ImageSmall from "../../../components/imageSmall/ImageSmall";
import style from "../oneNews.scss";
import art20221221PreviewImagesDat from "./art20221221PreviewImagesDat";
// import swissPlasticExpo2023PreviewImagesDat from "./swissPlasticExpo2023PreviewImagesDat";

const Art20221221 = () => {
  return (
    <>
      <main>
        <div>
          {/** 
      <div class={style.hdr_fr}>
        <h1>News</h1>
        <div class={[style.bgi_lei + " revbgsm"]} />
      </div>
      */}

{/**
 * 
          <span itemprop="primaryImageOfPage" itemscope="">
            <link
              itemprop="url"
              href="https://www.hgd-polymers.ch/assets/images/news/art20221221/16x9/art20221221-16x9-1440w.jpg"
            />
          </span> * 
 */}


          <div class={style.os_fr}>
            <div>
              {/**
               *               <ImageMarkup imageConfig={art20221221ImagesDat} />
               *
               */}
              <ImageSmall imageConfig={art20221221PreviewImagesDat} />

              <meta
                itemprop="image"
                content="https://www.hgd-polymers.ch/assets/images/news/art20221221/1x1/art20221221-1x1-1080w.jpg"
              />
              <meta
                itemprop="image"
                content="https://www.hgd-polymers.ch/assets/images/news/art20221221/16x9/art20221221-4x3-1440w.jpg"
              />
              <meta
                itemprop="image"
                content="https://www.hgd-polymers.ch/assets/images/news/art20221221/16x9/art20221221-16x9-1440w.jpg"
              />
            </div>

            <div class={style.os_txt}>
              <div
                class={style.os_date}
                itemprop="datePublished"
                content="2022-12-21T08:00:00+08:00"
              >
                21. Dezember 2022
              </div>
              <meta
                itemprop="dateModified"
                content="2022-12-21T08:00:00+08:00"
              />
              <h1 itemprop="headline">
                Barlog Plastics: HGD Polymers neuer Distributor für die Schweiz
                und Liechtenstein
              </h1>
              <section>
                <p>
                  Die Barlog Plastics GmbH aus Overath gewinnt mit HGD Polymers
                  GmbH und ihrem Geschäftsführer Halil Sevimli einen neuen
                  Distributor für die Schweiz und Liechtenstein. Ab Januar 2023
                  übernimmt HGD Polymers exklusiv den Vertrieb des Material- und
                  Dienstleistungsportfolios für Barlog Plastics. Halil Sevimli,
                  Geschäftsführer von HGD Polymers GmbH, gilt als ausgewiesener
                  Kunststoff-Experte mit langjähriger Markt- und
                  Vertriebserfahrung.
                </p>

                <p>
                  „Wir möchten unseren Kunden stets den besten Service bieten -
                  dazu gehört auch ein lokaler Ansprechpartner vor Ort“, so
                  Peter Barlog, geschäftsführender Gesellschafter der Barlog
                  Plastics GmbH. „Wir freuen uns, dass wir mit der HGD Polymers
                  GmbH und ihrem Geschäftsführer Halil Sevimli einen neuen
                  Distributor für die Schweiz und Liechtenstein gewinnen konnten
                  und unseren Kunden so in den beiden Ländern ab 2023 ein „Mehr
                  an Service“ bieten können“, ergänzt er.
                </p>

                <p>
                  Die lokale Nähe zu den Kunden bietet neben der verbesserten
                  Lagerhaltung auch den Vorteil des schnellen technischen
                  Services. Außerdem kann so ein deutlich erweitertes
                  Service-Angebot rund um die Kunststoff-Granulate geboten
                  werden. HGD Polymers übernimmt daher exklusiv den Vertrieb für
                  die Produktreihen POM, PBT, PPS, PEEK, PEK; PEKK, ABS/PC, LCP,
                  TPE, funktionalisierte Compounds sowie Recycling Compounds.
                  Darüber hinaus wird das gesamten Dienstleistungsangebots "von
                  der Idee bis zur Serie", bestehend aus CAE-Engineering &
                  Simulation, Rapid Prototyping & CNC-Frästeile, Spritzgegossene
                  Prototypen & Kleinserien, Laborprüfungen, Messtechnik &
                  Bauteildigitalisierung sowie Konditionierung zur Verfügung
                  stehen.
                </p>
                <p>
                  <strong>Weitere Informationen</strong>:{" "}
                  <a
                    class={style.os_txtlink}
                    href="https://www.barlog.de/"
                    target="_blank"
                  >
                    barlog.de
                  </a>
                  ,{" "}
                  <a
                    class={style.os_txtlink}
                    href="https://www.hgd-polymers.ch/"
                    target="_blank"
                  >
                    hgd-polymers.ch
                  </a>
                  .
                </p>
                <p class={style.os_source}>
                  Dieser Artikel wurde ursprünglich auf{" "}
                  <a
                    href="https://plasticker.de/Kunststoff_News_42473_Barlog_Plastics_HGD_Polymers_neuer_Distributor_fuer_die_Schweiz_und_Liechtenstein"
                    target="_blank"
                    class={style.os_txtlink}
                  >
                    plasticker.de
                  </a>{" "}
                  publiziert
                </p>
              </section>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Art20221221;
