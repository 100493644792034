const gabrielImagesDat = [
    {
      alt: "FARB MASTERBATCH, Grenzenlose Farbvielfalt",
      caption: "FARB MASTERBATCH, Grenzenlose Farbvielfalt",
      sizes: "(max-width: 768px) 768px, 1440px",
      sources: {
        sfallback: ["1440", "960", "/assets/images/gabriel/i1/gabriel1-1440w.jpg"],
        swebp: [
          ["768", "/assets/images/gabriel/i1/gabriel1-768w.webp"],
          ["1440", "/assets/images/gabriel/i1/gabriel1-1440w.webp"],
        ],
        sjpg: [
          ["768", "/assets/images/gabriel/i1/gabriel1-768w.jpg"],
          ["1440", "/assets/images/gabriel/i1/gabriel1-1440w.jpg"],
        ],
      },
      canEnlarge: true,
    },
    {
        alt: "MAXiTHEN LASERADDiTiV, PP Simply Magic",
        caption: "MAXiTHEN LASERADDiTiV, PP Simply Magic",
        sizes: "(max-width: 768px) 768px, 768px",
        sources: {
          sfallback: ["768", "1127", "/assets/images/gabriel/i2/gabriel2-768w.jpg"],
          swebp: [
            ["768", "/assets/images/gabriel/i2/gabriel2-768w.webp"],

          ],
          sjpg: [
            ["768", "/assets/images/gabriel/i2/gabriel2-768w.jpg"],

          ],
        },
        canEnlarge: false,
      },
      {
        alt: "MAXiTHEN LASERADDiTiV, SB Autumn green matt",
        caption: "MAXiTHEN LASERADDiTiV, SB Autumn green matt",
        sizes: "(max-width: 768px) 768px, 768px",
        sources: {
          sfallback: ["768", "1077", "/assets/images/gabriel/i3/gabriel3-768w.jpg"],
          swebp: [
            ["768", "/assets/images/gabriel/i3/gabriel3-768w.webp"],
          ],
          sjpg: [
            ["768", "/assets/images/gabriel/i3/gabriel3-768w.jpg"],
          ],
        },
        canEnlarge: false,
      },
      {
        alt: "Tuben Verpackungsindustrie",
        caption: "Tuben Verpackungsindustrie",
        sizes: "(max-width: 768px) 768px, 1440px",
        sources: {
          sfallback: ["1440", "639", "/assets/images/gabriel/i4/gabriel4-1440w.jpg"],
          swebp: [
            ["768", "/assets/images/gabriel/i4/gabriel4-768w.webp"],
            ["1440", "/assets/images/gabriel/i4/gabriel4-1440w.webp"],
          ],
          sjpg: [
            ["768", "/assets/images/gabriel/i4/gabriel4-768w.jpg"],
            ["1440", "/assets/images/gabriel/i4/gabriel4-1440w.jpg"],
          ],
        },
        canEnlarge: true,
      },
      {
        alt: "MAXiTHEN LASERADDiTiV, Holz Optik personalisiert",
        caption: "MAXiTHEN LASERADDiTiV, Holz Optik personalisiert",
        sizes: "(max-width: 768px) 768px, 1440px",
        sources: {
          sfallback: ["1440", "810", "/assets/images/gabriel/i5/gabriel5-1440w.jpg"],
          swebp: [
            ["768", "/assets/images/gabriel/i5/gabriel5-768w.webp"],
            ["1440", "/assets/images/gabriel/i5/gabriel5-1440w.webp"],
          ],
          sjpg: [
            ["768", "/assets/images/gabriel/i5/gabriel5-768w.jpg"],
            ["1440", "/assets/images/gabriel/i5/gabriel5-1440w.jpg"],
          ],
        },
        canEnlarge: true,
      },
      {
        alt: "MAXiTHEN LASERADDiTiV, Holz Optik",
        caption: "MAXiTHEN LASERADDiTiV, Holz Optik",
        sizes: "(max-width: 768px) 768px, 1440px",
        sources: {
          sfallback: ["1440", "1080", "/assets/images/gabriel/i6/gabriel6-1440w.jpg"],
          swebp: [
            ["768", "/assets/images/gabriel/i6/gabriel6-768w.webp"],
            ["1440", "/assets/images/gabriel/i6/gabriel6-1440w.webp"],
          ],
          sjpg: [
            ["768", "/assets/images/gabriel/i6/gabriel6-768w.jpg"],
            ["1440", "/assets/images/gabriel/i6/gabriel6-1440w.jpg"],
          ],
        },
        canEnlarge: true,
      },

  ];
  
  export default gabrielImagesDat;
  