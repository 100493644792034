const handelTableDat = {
    headers: ['Polymer:', 'Kürzel:'],
    data: [
        ['Acrylester-Styrol-Acrylnitril', 'ASA'],
        ['Acrylnitril-Butadien-Styrol', 'ABS'],
        ['Styrol-Methylmethacrylat', 'SMMA'],
        ['Celluloseacetat', 'CA'],
        ['Cyclo-Olefin-Copolymere', 'COC'],
        ['Ethylenvinylacetat', 'EVA'],
        ['Flüssigkristall-Polymere', 'LCP'],
        ['High Impact Polystyrene', 'HIPS'],
        ['Methylacrylat/Butadien/ Styrol', 'MBS'],
        ['Polylactid (Polymilchsäure)', 'PLA'],
        ['Polyamid', 'PA'],
        ['Polybutylenterephthalat', 'PBT'],
        ['Polycarbonate', 'PC'],
        ['Polyether-Block-Amid', 'PEBA'],
        ['Polyetherimid', 'PEI'],
        ['Polyetherketone', 'PEK, PEEK u.a.'],
        ['Polyethersulfon', 'PES'],
        ['Polyethylen', 'PE'],
        ['Polyethylenterephthalat', 'PET'],
        ['Polyimid', 'PI'],
        ['Polymethacrylmethylimid', 'PMMI'],
        ['Polymethylmethacrylat', 'PMMA'],
        ['Polyoxymethylen oder Polyacetal', 'POM'],
        ['Polyphenylether', 'PPE oder PPO'],
        ['Polyphenylensulfid', 'PPS'],
        ['Polyphthalamid', 'PPA'],
        ['Polypropylen', 'PP'],
        ['Polystyrol', 'PS'],
        ['Polystyrol schlagfest', 'SB'],
        ['Polysulfon', 'PSU'],
        ['Polytetrafluorethylen', 'PTFE'],
        ['Polyvinylidenfluorid', 'PVDF'],
        ['Styrol-Acrylnitril- Copolymerisat', 'SAN'],
        ['Styrol-Butadien-Styrol', 'SBS'],
        ['Thermoplastisches Polyurethan', 'TPU oder TPE-U'],
    ]
}

export default handelTableDat;