import { useEffect } from "preact/hooks";

import "../../../style/bgimgs.scss";
import "../../../style/bgAnimations.css";
import style from "../prodStyle.scss";
import homeStyle from "../../home/style.scss";
import ImageMarkup from "../../../components/imageMarkup/ImageMarkup";
import gabrielImagesDat from "./gabrielImagesDat";

const GabrielChemie = () => {
  useEffect(() => {
    if (typeof "window" !== undefined) {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <>
      <main>
        <div class={style.pp}>
          <meta itemprop="datePublished" content="2022-12-13T08:00:00+08:00" />
          <meta itemprop="dateModified" content="2022-12-13T08:00:00+08:00" />
          <meta itemprop="lastReviewed" content="2022-12-13T08:00:00+08:00" />
          <span itemprop="primaryImageOfPage" itemscope="">
            <link
              itemprop="url"
              href="/assets/images/gabriel/i1/gabriel1-1440w.jpg"
            />
          </span>
          <div class={style.hdr_fr}>
            <h1 itemprop="name">GABRIEL Chemie</h1>
            <div class="headerimage bgsm revbgsm" />
          </div>

          <section class={style.pp_sec}>
            <div class={style.pp_left}>
              <div class={style.pp_log}>
                <img
                  width={600}
                  height={295}
                  src="/assets/logos/gabriel-logo.png"
                  alt="GABRIEL Chemie Logo"
                  loading="lazy"
                />
              </div>

              <p class={style.pp_p} itemprop="description">
                GABRIEL Chemie ist auf das Färben und Veredeln von
                thermoplastischen Kunststoffen spezialisiert und bieten für die
                verschiedensten Branchen Farbmasterbatch, Additivmasterbatch und
                Kombinationsmasterbatch für Kunststoffartikel an. Kunststoffe
                sind wahre Allrounder und aus unserem täglichen Leben nicht mehr
                wegzudenken. Bevor Kunststoffartikel zum Endkonsumenten
                gelangen, durchlaufen sie allerdings noch einige wichtige
                Produktionsschritte. "BRINGING LIFE TO PLASTICS" ist genau das,
                was Gabriel-Chemie tut: die Masterbatches haucht Kunststoff
                Leben ein, verleiht dem farblosen und funktionsarmen Roh-Polymer
                lebendige Farbe, Effekte und funktionelle Additive.
              </p>

              <p class={style.pp_p}>
                Das Produktportfolio umfasst Additiv-Masterbatch, Farb- und
                Effekt-Masterbatch, Schwarz- und Weiss-Masterbatch sowie
                Kombinations-Masterbatch.
              </p>

              <p class={style.pp_p}>
                Als - nunmehr in 2. Generation - inhabergeführtes
                Familienunternehmen zählt nach über 60-jährigem Bestehen heute
                zu den führenden Masterbatch-Produzenten Europas.
              </p>

              <a
                class={style.pp_a}
                target="_blank"
                href="https://www.gabriel-chemie.com/index.php/"
                itemprop="significantLink"
              >
                ZUR WEBSEITE
              </a>

              <div class={style.pp_dvdr} />

              <div class={style.pp_adr}>
                <p class={style.pp_adr_t}>Gabriel-Chemie Deutschland GmbH</p>
                <p>Ritzenschattenhalb 1</p>
                <p>87480 Weitnau/Oberallgäu</p>
                <p>Deutschland</p>
              </div>
            </div>

            <div class={style.pp_right}>
              <div class={style.pp_listblock}>
                <h2 class={style.subhdr}>Produkte</h2>

                <ul class={style.pp_stdlist}>
                  <li>Additiv-Masterbatch</li>
                  <li>Farb-Masterbatch</li>
                  <li>Weiss & Schwarz Masterbatch</li>
                  <li>Kombinations-Masterbatch</li>
                  <li>Effekt- Masterbatch</li>
                </ul>
              </div>

              <div class={style.imgblock}>
                {gabrielImagesDat.map((it) => (
                  <ImageMarkup imageConfig={it} />
                ))}
              </div>
            </div>
          </section>

          <section class="headerimage4 nopad">
            <div class={style.pp_fr1024}>
              <div class={[homeStyle.cardouter + " " + homeStyle.inprod]}>
                <div class={homeStyle.cardfr}>
                  <h2>DER SCHNELLSTE WEG ZUR BESTEN BETREUUNG!</h2>
                </div>
                <div class={homeStyle.clfl}>
                  <a href="tel:+41714404444">+41 (0) 71 440 44 44</a>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};

export default GabrielChemie;
