const eurotecTableDat = {
    headers: ['Polymer:', 'Handelsnamen:'],
    data: [
        ['PA6, PA6.6, PA66/6, PA6.10', 'TECOMID'],
        ['PBT', 'TECODUR'],
        ['PPA', 'TECOMID'],
        ['PET', 'TECOPET'],
        ['PPO', 'TECOTEK'],
        ['PC, PC/ABS', 'TECOTEK'],
        ['PESU, PPSU, PSU', 'TECOTEK'],
        ['PP, PE', 'TECOLEN'],
        ['POM', 'TECOFORM'],
        ['PEEK', 'TECOPEEK'],
        ['PK', 'TECOKET'],
        ['PPS', 'TECOTRON'],
        ['TPU', 'TECOFLEX']
    ]
}

export default eurotecTableDat;