import { Link } from "preact-router/match";
import DistributionSVG from "../../../components/svgs/DistributionSVG";
import style from "../styleSingleService.scss";
import "../../../style/bgimgs.scss";
import style2 from "../../produkte/prodStyle.scss";
import homeStyle from "../../home/style.scss";
import TradeSVG from "../../../components/svgs/TradeSVG";
import EducationSVG from "../../../components/svgs/EducationSVG";
import EngineeringSVG from "../../../components/svgs/EngineeringSVG";
import { useEffect } from "preact/hooks";

const Schulungen = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
  }, []);
  return (
    <>
      <main>
        <div>
          <meta itemprop="datePublished" content="2022-12-13T08:00:00+08:00" />
          <meta itemprop="dateModified" content="2022-12-13T08:00:00+08:00" />
          <meta itemprop="lastReviewed" content="2022-12-13T08:00:00+08:00" />
          <span itemprop="primaryImageOfPage" itemscope="">
            <link
              itemprop="url"
              href="https://www.hgd-polymers.ch/assets/images/etc/education/bgcard4-ai-1440w.jpg"
            />
          </span>
          <div class={style.ss_bgitopfr}>
            <div class={style.ss_bg3} />
            <div class={style.ss_bgitopinner}>
              <EducationSVG />
              <h1 itemprop="name">SCHULUNGEN</h1>
            </div>
          </div>
          <section>
            <div class={style.ss_sec}>
              <div class={style.ss_lead} itemprop="description">
                Aus der Praxis für die Praxis - durch die langjährige Erfahrung
                von BARLOG Plastics aus den unterschiedlichsten Bereichen der
                Kunststofftechnik erhalten Sie professionelle Schulungen, die
                Ihr Team massgeblich voranbringen.
              </div>

              <div class={style.ss_mainc}>
                <h2>Wissen für mehr Produktivität</h2>
                <p>
                  Wir glauben daran, dass Wissen heute mehr wert ist als je
                  zuvor. Deshalb geben wir es in der Akademie auch an unsere
                  Kunden weiter. Hier fließen das Know-how und die langjährige
                  Erfahrung aus allen Disziplinen der BARLOG Plastics GmbH
                  zusammen und werden ständig ergänzt.
                </p>
                <p>
                  Dazu nutzen wir auch den Input unseres grossen
                  Spezialisten-Netzwerkes und unsere täglich wachsende
                  Wissensdatenbank.
                </p>

                <p>Fragen sie uns an!</p>
                <br />
                <br />
              </div>
              {/** 
          <ImageMarkup
            imageConfig={{
              alt: "alt description",
              caption:
                "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. CAP1",
              sizes: "(max-width: 768px) 768px, 1440px",
              sources: {
                sfallback: [
                  "1440",
                  "924",
                  "/assets/images/etc/engineering/chart/engineering_chart-1440w.jpg",
                ],
                swebp: [
                  [
                    "768",
                    "/assets/images/etc/engineering/chart/engineering_chart-768w.webp",
                  ],
                  [
                    "1440",
                    "/assets/images/etc/engineering/chart/engineering_chart-1440w.webp",
                  ],
                ],
                sjpg: [
                  [
                    "768",
                    "/assets/images/etc/engineering/chart/engineering_chart-768w.jpg",
                  ],
                  [
                    "1440",
                    "/assets/images/etc/engineering/chart/engineering_chart-1440w.jpg",
                  ],
                ],
              },
              canEnlarge: true,
            }}
          />
          */}
            </div>
          </section>
          <br />
          <br />
          <br />
          <div class={style.ss_navblockfr}>
            <nav>
              <Link href="/leistungen/kunststoff-distribution">
                <DistributionSVG />
                <span>
                  KUNSTSTOFF <br /> DISTRIBUTION
                </span>
              </Link>

              <Link href="/leistungen/kunststoff-handel">
                <TradeSVG />
                <span>
                  KUNSTSTOFF <br /> HANDEL
                </span>
              </Link>

              <Link href="/leistungen/engineering-cae">
                <EngineeringSVG />
                <span>
                  ENGINEERING <br /> & CAE
                </span>
              </Link>
            </nav>
          </div>

          <section class="headerimage4 nopad">
            <div class={style2.pp_fr1024}>
              <div class={[homeStyle.cardouter + " " + homeStyle.inprod]}>
                <div class={homeStyle.cardfr}>
                  <h2>DER SCHNELLSTE WEG ZUR BESTEN BETREUUNG!</h2>
                </div>
                <div class={homeStyle.clfl}>
                  <a href="tel:+41714404444">+41 (0) 71 440 44 44</a>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};

export default Schulungen;
