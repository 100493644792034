import { useEffect } from "preact/hooks";
import "../../../style/bgimgs.scss";
import "../../../style/bgAnimations.css";
import style from "../prodStyle.scss";
import homeStyle from "../../home/style.scss";
import eurotecTableDat from "./eurotecTableDat";
import eurotecImagesDat from "./eurtotecImagesDat";
import ImageMarkup from "../../../components/imageMarkup/ImageMarkup";

const Eurotec = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <>
      <main>
        <div class={style.pp}>
          <meta itemprop="datePublished" content="2022-12-13T08:00:00+08:00" />
          <meta itemprop="dateModified" content="2022-12-13T08:00:00+08:00" />
          <meta itemprop="lastReviewed" content="2022-12-13T08:00:00+08:00" />
          <span itemprop="primaryImageOfPage" itemscope="">
            <link
              itemprop="url"
              href="/assets/images/eurotec/i1/eurotec1-1440w.jpg"
            />
          </span>
          <div class={style.hdr_fr}>
            <h1 itemprop="name">Eurotec</h1>
            <div class="headerimage bgsm revbgsm" />
          </div>

          <section class={style.pp_sec}>
            <div class={style.pp_left}>
              <div class={style.pp_log}>
                <img
                  width={600}
                  height={140}
                  src="/assets/logos/eurotec-logo.png"
                  alt="eurotec Logo"
                  loading="lazy"
                />
              </div>

              <p class={style.pp_p} itemprop="description">
                Eurotec hat seinen Sitz in der Europäischen Freizone/Corlu und
                stellt seit 2004 technische Kunststoffe für verschiedene
                Sektoren her, vor allem für die Automobil- und Transportbranche,
                die Elektro- und Elektronikindustrie, Haushaltsgeräte, Sport und
                Freizeit, Sicherheitsausrüstungen, Garten- und Elektrowerkzeuge,
                die medizinische Industrie, Bau- und landwirtschaftliche Geräte
                sowie die Möbelindustrie.
              </p>

              <p class={style.pp_p}>
                Das Unternehmen wurde in der Freizone gegründet, um ein
                weltweiter Lieferant zu werden, und hat sich in kürzester Zeit
                zu einem führenden Hersteller und einem unverzichtbaren
                Lösungspartner für technische Kunststoffe entwickelt.
              </p>

              <a
                class={style.pp_a}
                target="_blank"
                href="https://www.eurotec-ep.com/de/"
                itemprop="significantLink"
              >
                ZUR WEBSEITE
              </a>

              <div class={style.pp_dvdr} />

              <div class={style.pp_adr}>
                <p class={style.pp_adr_t}>Eurotec Technische Kunststoffe</p>
                <p>Karamehmet Mah. ASB</p>
                <p>Avrasya Bulvari No:8, TR 59930</p>
                <p>Ergene, Tekirdag</p>
                <p>Türkei</p>
              </div>
            </div>

            <div class={style.pp_right}>
              <table class={[style.pp_table + " " + style.pp_mgtop85]}>
                <thead>
                  <tr>
                    {eurotecTableDat.headers.map((it) => (
                      <th key={it}>{it}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {eurotecTableDat.data.map((it, ind) => (
                    <tr
                      class={
                        (ind + 1) % 2 ? style.pp_table_c1 : style.pp_table_c2
                      }
                      key={`${it[1]}:${ind}`}
                    >
                      {it.map((itt) => (
                        <td key={itt}>{itt}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div class={style.imgblock}>
                {eurotecImagesDat.map((it) => (
                  <ImageMarkup imageConfig={it} />
                ))}
              </div>
            </div>
          </section>

          <section class="headerimage4 nopad">
            <div class={style.pp_fr1024}>
              <div class={[homeStyle.cardouter + " " + homeStyle.inprod]}>
                <div class={homeStyle.cardfr}>
                  <h2>DER SCHNELLSTE WEG ZUR BESTEN BETREUUNG!</h2>
                </div>
                <div class={homeStyle.clfl}>
                  <a href="tel:+410714404444">+41 (0) 71 440 44 44</a>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};

export default Eurotec;
