const art20221221PreviewImagesDat = 
  {
    alt: "Peter Barlog (l.), Halil Sevimli - (Bild: Barlog Plastics).",
    caption: "Peter Barlog (l.), Halil Sevimli",
    sizes: "(max-width: 768px) 768px, 768px",
    sources: {
      sfallback: [
        "768",
        "432",
        "/assets/images/news/art20221221/16x9/art20221221-16x9-768w.jpg",
      ],
      swebp: [
        [
          "768",
          "/assets/images/news/art20221221/16x9/art20221221-16x9-768w.webp",
        ],
      ],
      sjpg: [
        [
          "768",
          "/assets/images/news/art20221221/16x9/art20221221-16x9-768w.jpg",
        ],
      ],
    },
  };

export default art20221221PreviewImagesDat;
