import "../../../style/bgimgs.scss";
import "../../../style/bgAnimations.css";
import style from "../../produkte/prodStyle.scss";
import uuStyle from "../ueberuns/uustyle.scss";
import { useEffect } from "preact/hooks";

const AGB = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <>
      <main>
        <div class={uuStyle.uu}>
          <meta itemprop="datePublished" content="2022-12-13T08:00:00+08:00" />
          <meta itemprop="dateModified" content="2022-12-13T08:00:00+08:00" />
          <meta itemprop="lastReviewed" content="2022-12-13T08:00:00+08:00" />
          <span itemprop="primaryImageOfPage" itemscope="">
            <link itemprop="url" href="/assets/logos/logo600x600.png" />
          </span>
          <div class={style.hdr_fr}>
            <h1 itemprop="name">AGB</h1>
            <div class="headerimage bgsm revbgsm" />
          </div>

          <section class={uuStyle.pltxt}>
            <div class={uuStyle.uu_fr}>
              <h2>1. Gültigkeit</h2>

              <p>
                Diese Bedingungen gelten für alle Verkäufe und Lieferungen
                vorbehältlich anderweitiger schriftlicher Vereinbarungen.
                (Mündliche Vereinbarungen sind unverbindlich.)
              </p>

              <h2>2. Preise</h2>

              <p>
                Die Preise verstehen sich netto und franko, inkl. Verpackung,
                exkl. MwSt. in frei verfügbaren Schweizerfranken.
              </p>
              <p>
                Wir behalten uns eine Preisanpassung vor, falls zwischen dem
                Zeitpunkt der Auftragsbestätigung und der effektiven Lieferung
                die Währungskurse, Fracht -, Zollsätze und andere Kosten ändern
                oder falls die vorgesehenen direkten Transportwege nicht
                unbeeinträchtigt benutzt werden können.
              </p>

              <h2>3. Liefertermine</h2>

              <p>
                Der angegebene Liefertermin steht unter Vorbehalt der
                rechtzeitigen Selbstbelieferung durch unsere Lieferanten.
                Lieferhindernisse, die wir nicht zu vertreten haben, wie z.B.
                Arbeitskonflikte, Betriebsstörungen (bei uns oder unseren
                Lieferanten), Epidemien, Krieg, Mobilmachung, Aufruhr,
                Elementarereignisse, behördliche Massnahmen, entbinden uns von
                der Vertragserfüllung und dem Käufer steht kein
                Schadenersatzanspruch zu.
              </p>

              <p>
                Wir haften nicht für Schäden wegen Lieferungsverzug, noch
                berechtigt Lieferverzug den Käufer zu Schadenersatz oder
                Rücktritt vom Vertrag.
              </p>

              <h2>4. Versand und Versicherung</h2>

              <p>
                Nutzen und Gefahr gehen spätestens mit Abgang der Lieferung bei
                uns auf den Käufer über, selbst wenn die Lieferung franko, cif,
                fob, unter ähnlicher Klausel erfolgt. Wird der Versand verzögert
                oder verunmöglicht aus Gründen, die wir nicht zu vertreten
                haben, so wird die Lieferung auf Rechnung und Gefahr des Käufers
                gelagert.
              </p>

              <h2>5. Garantie und Haftung</h2>
              <p>
                Wir haften für musterkonforme und handelsübliche Lieferung der
                vereinbarten Ware. Der Käufer hat die Ware sofort nach Erhalt
                und bevor er sie gebraucht oder verarbeitet, zu prüfen und
                allfällige Mängel unverzüglich, spätestens innerhalb fünf Tagen
                schriftlich zu rügen, ansonsten die Lieferung als durch den
                Käufer genehmigt gilt. Der Käufer hat die Unterlagen für die
                Identifizierung der Ware und der Mängel einzusenden. Die Haftung
                für einen bestimmten Verwendungszweck oder Verarbeitungserfolg
                der Ware und für Verzögerungen in der Ablieferung ist
                ausgeschlossen. Die in Publikationen (wie Prospekte,
                Merkblätter, Kataloge, Offerten usw.) enthaltenen Empfehlungen,
                Anweisungen, Rezepturen und ähnliche Daten, sowie die
                anwendungs- und verfahrenstechnischen Beratungen werden von uns
                nach bestem Wissen und Gewissen, aber ohne Haftbarkeit
                abgegeben. Bei begründeter Mängelrüge sind wir berechtigt,
                Ersatzware zu liefern. Wir haften nur für einen allfälligen
                Minderwert der Ware, und unsere Haftung ist in jedem Fall
                beschränkt auf den Fakturawert der Ware. Wir haften nicht für
                indirekte oder mittelbare Schäden oder Nachteile, wie
                Produktionsbehinderungen, Verarbeitungskosten oder ähnliche
                Folgen.
              </p>

              <h2>6. Fakturierung und Zahlung</h2>
              <p>
                Massgebend für die Fakturierung ist das Abgangsgewicht bzw. das
                von uns in Rechnung gestellte Originalgewicht. Die vereinbarte
                Gewichtsmenge kann von uns bis zu 10% unter- oder überschritten
                werden.
              </p>
              <p>
                Die Zahlungstermine sind auch einzuhalten, wenn Transport,
                Ablieferung oder Abnahme der Lieferung sich aus Gründen
                verspäten, die dem Verkäufer nicht angelastet werden können. Der
                Käufer verzichtet auf die Rückstellung oder Kürzung von
                Kaufpreiszahlungen.
              </p>
              <p>
                Ist der Käufer mit der Abnahme der Waren oder der Zahlung in
                Verzug, so können wir ohne Nachfristansetzung vom Vertrag
                zurücktreten und den wegen Nichterfüllung entstandenen Schaden
                geltend machen. Wir sind auch berechtigt nicht abgenommene
                Mengen ganz oder teilweise nachzuliefern oder zu stornieren und
                unseren Schaden und Verzugszins geltend zu machen.
              </p>
              <p>
                Bei Änderung der finanziellen Lage des Käufers oder
                Nichteinhaltung der Zahlungskonditionen und -termine können wir
                weitere Lieferungen gleichermassen einstellen oder stornieren.
                Dies gilt auch, wenn bei einem Verkauf auf Abruf die Ware
                innerhalb der vorgesehenen Frist nicht abgerufen wird, ohne dass
                wir den Käufer zum Abruf auffordern müssen.
              </p>

              <h2>7. Erfüllungsort und Gerichtstand</h2>

              <p>
                Erfüllungsort für Lieferung und Zahlung, sowie Gerichtsstand für
                beide Teile ist Altstätten / SG, Schweiz.
              </p>

              <h2>8. Anwendbares Recht</h2>

              <p>Massgeblich ist ausschliesslich Schweizerisches Recht.</p>
              <p>01.12.2022</p>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};

export default AGB;
