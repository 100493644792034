import { useEffect } from "preact/hooks";
import ImageMarkup from "../../../components/imageMarkup/ImageMarkup";
import "../../../style/bgimgs.scss";
import "../../../style/bgAnimations.css";
import style from "../prodStyle.scss";
import homeStyle from "../../home/style.scss";
import coppexImagesDat from "./coppexImagesDat";

const Coppex = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <>
      <main>
        <div class={style.pp}>
          <meta itemprop="datePublished" content="2022-12-13T08:00:00+08:00" />
          <meta itemprop="dateModified" content="2022-12-13T08:00:00+08:00" />
          <meta itemprop="lastReviewed" content="2022-12-13T08:00:00+08:00" />
          <span itemprop="primaryImageOfPage" itemscope="">
            <link itemprop="url" href="/assets/images/coppex/i3/coppex_3.jpg" />
          </span>
          <div class={style.hdr_fr}>
            <h1 itemprop="name">COPPEX</h1>
            <div class="headerimage bgsm revbgsm" />
          </div>

          <section class={style.pp_sec}>
            <div class={style.pp_left}>
              <div class={style.pp_log}>
                <img
                  width={600}
                  height={295}
                  src="/assets/logos/coppex-logo.png"
                  alt="COPPEX Logo"
                  loading="lazy"
                />
              </div>

              <p class={style.pp_p} itemprop="description">
                COPPEX - das Reinigungsgranulat für Spritzguss­maschinen,
                Extrusions-, Hohlkörper- und Folienblas­anlagen.
              </p>

              <p class={style.pp_p}>
                COPPEX wurde für die Kunststoffindustrie als schnelles und
                zeitsparendes Reinigungsgranulat entwickelt. Seine universellen
                Einsatzmöglichkeiten erstrecken sich von Farb- und/oder
                Materialwechseln über einen breiten Temperaturbereich von 180°C
                bis 300°C, bis hin zur problemlosen Heisskanalreinigung.
              </p>

              <a
                class={style.pp_a}
                target="_blank"
                href="https://coppex.de/index.html"
                itemprop="significantLink"
              >
                ZUR WEBSEITE
              </a>

              <div class={style.pp_dvdr} />

              <div class={style.pp_adr}>
                <p class={style.pp_adr_t}>SEMRAU Technik</p>
                <p>Zaunlücke 28</p>
                <p>97237 Altertheim</p>
                <p>Deutschland</p>
              </div>
            </div>

            <div class={style.pp_right}>
              <div class={style.pp_listblock}>
                <h2 class={style.subhdr}>Steigerung der Produktivität</h2>

                <ul class={style.pp_stdlist}>
                  <li>
                    Einsatz im laufenden Betrieb ohne Parameterveränderung
                  </li>
                  <li>erhöht die Prozesssicherheit</li>
                  <li>Heisskanalgeeignet</li>
                  <li>grosser Temperaturbereich</li>
                  <li>einfache Handhabung</li>
                  <li>gleichzeitige Reinigung des Werkzeugs</li>
                  <li>
                    beugt bei regelmässiger Verwendung hartnäckigen
                    Verschmutzungen vor
                  </li>
                  <li>längere Produktionszyklen bei regelmäßiger Verwendung</li>
                </ul>
              </div>
              <br />
              <br />
              <div class={style.pp_listblock}>
                <h2 class={style.subhdr}>Kostenvorteile</h2>

                <ul class={style.pp_stdlist}>
                  <li>geringere Sillstandzeit</li>
                  <li>reduziert Ausschuss</li>
                  <li>weniger Rohstoffverlust</li>
                  <li>Zeitersparnis durch eine schnelle Reinigung</li>
                </ul>
              </div>

              <div class={style.imgblock}>
                {coppexImagesDat.map((it) => (
                  <ImageMarkup imageConfig={it} />
                ))}
              </div>
            </div>
          </section>

          <section class="headerimage4 nopad">
            <div class={style.pp_fr1024}>
              <div class={[homeStyle.cardouter + " " + homeStyle.inprod]}>
                <div class={homeStyle.cardfr}>
                  <h2>DER SCHNELLSTE WEG ZUR BESTEN BETREUUNG!</h2>
                </div>
                <div class={homeStyle.clfl}>
                  <a href="tel:+41714404444">+41 (0) 71 440 44 44</a>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};

export default Coppex;
