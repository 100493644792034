const barlogImagesDat = [
  {
    alt: "KEBABLEND / M und MW",
    caption: "KEBABLEND / M und MW",
    sizes: "(max-width: 768px) 768px, 1440px",
    sources: {
      sfallback: ["1440", "428", "/assets/images/barlog/i1/barlog1-1440w.jpg"],
      swebp: [
        ["768", "/assets/images/barlog/i1/barlog1-768w.webp"],
        ["1440", "/assets/images/barlog/i1/barlog1-1440w.webp"],
      ],
      sjpg: [
        ["768", "/assets/images/barlog/i1/barlog1-768w.jpg"],
        ["1440", "/assets/images/barlog/i1/barlog1-1440w.jpg"],
      ],
    },
    canEnlarge: true,
  },
  {
    alt: "PPS KEBABLEND M",
    caption: "PPS KEBABLEND M",
    sizes: "(max-width: 768px) 768px, 1440px",
    sources: {
      sfallback: ["1440", "518", "/assets/images/barlog/i6/barlog6-1440w.jpg"],
      swebp: [
        ["768", "/assets/images/barlog/i6/barlog6-768w.webp"],
        ["1440", "/assets/images/barlog/i6/barlog6-1440w.webp"],
      ],
      sjpg: [
        ["768", "/assets/images/barlog/i6/barlog6-768w.jpg"],
        ["1440", "/assets/images/barlog/i6/barlog6-1440w.jpg"],
      ],
    },
    canEnlarge: true,
  },
  {
    alt: "TPE KEBAFLEX, Griff Elektrowerkzeug",
    caption: "TPE KEBAFLEX, Griff Elektrowerkzeug",
    sizes: "(max-width: 768px) 768px, 1440px",
    sources: {
      sfallback: ["1440", "518", "/assets/images/barlog/i2/barlog2-1440w.jpg"],
      swebp: [
        ["768", "/assets/images/barlog/i2/barlog2-768w.webp"],
        ["1440", "/assets/images/barlog/i2/barlog2-1440w.webp"],
      ],
      sjpg: [
        ["768", "/assets/images/barlog/i2/barlog2-768w.jpg"],
        ["1440", "/assets/images/barlog/i2/barlog2-1440w.jpg"],
      ],
    },
    canEnlarge: true,
  },
  {
    alt: "Abschirmende Compounds, Stromverteilerdose",
    caption: "Abschirmende Compounds, Stromverteilerdose",
    sizes: "(max-width: 768px) 768px, 1440px",
    sources: {
      sfallback: ["1440", "518", "/assets/images/barlog/i7/barlog7-1440w.jpg"],
      swebp: [
        ["768", "/assets/images/barlog/i7/barlog7-768w.webp"],
        ["1440", "/assets/images/barlog/i7/barlog7-1440w.webp"],
      ],
      sjpg: [
        ["768", "/assets/images/barlog/i7/barlog7-768w.jpg"],
        ["1440", "/assets/images/barlog/i7/barlog7-1440w.jpg"],
      ],
    },
    canEnlarge: true,
  },
  {
    alt: "Kabelverschraubung PBT KEBATER",
    caption: "Kabelverschraubung PBT KEBATER",
    sizes: "(max-width: 768px) 768px, 1140px",
    sources: {
      sfallback: ["1140", "337", "/assets/images/barlog/i3/barlog3-1140w.jpg"],
      swebp: [
        ["768", "/assets/images/barlog/i3/barlog3-768w.webp"],
        ["1140", "/assets/images/barlog/i3/barlog3-1140w.webp"],
      ],
      sjpg: [
        ["768", "/assets/images/barlog/i3/barlog3-768w.jpg"],
        ["1140", "/assets/images/barlog/i3/barlog3-1140w.jpg"],
      ],
    },
    canEnlarge: true,
  },
];

export default barlogImagesDat;
