import { h } from "preact";
import "../../../style/bgimgs.scss";
import "../../../style/bgAnimations.css";
import style from "../prodStyle.scss";
import homeStyle from "../../home/style.scss";
import barlogTableDat from "./barlogTableDat";
import barlogImagesDat from "./barlogImagesDat";
import ImageMarkup from "../../../components/imageMarkup/ImageMarkup";
import { useEffect } from "preact/hooks";

const BarlogPlastics = () => {
  useEffect(() => {
    if (typeof "window" !== undefined) {
      window.scrollTo(0, 0);
    }
  }, []);
  return (
    <>
      <main>
        <div class={style.pp}>
          <meta itemprop="datePublished" content="2022-12-13T08:00:00+08:00" />
          <meta itemprop="dateModified" content="2022-12-13T08:00:00+08:00" />
          <meta itemprop="lastReviewed" content="2022-12-13T08:00:00+08:00" />
          <span itemprop="primaryImageOfPage" itemscope="">
            <link itemprop="url" href="/assets/logos/logo600x600.png" />
          </span>

          <div class={style.hdr_fr}>
            <h1 itemprop="name">BARLOG Plastics</h1>
            <div class="headerimage bgsm revbgsm" />
          </div>

          <section class={style.pp_sec}>
            <div class={style.pp_left}>
              <div class={style.pp_log}>
                <img
                  width={300}
                  height={162}
                  src="/assets/logos/barlog-plastics-logo-300w.png"
                  alt="BARLOG Plastics Logo"
                  loading="lazy"
                />
              </div>

              <p class={style.pp_p} temprop="description">
                Die BARLOG Plastics GmbH ist ein Full-Service-Anbieter für die
                Herstellung und den Vertrieb von Kunststoff-Compounds, die
                Entwicklung von Kunststoffkomponenten, sowie Prototypen- und
                Kleinserienfertigung.
              </p>

              <p class={style.pp_p}>
                Das inhabergeführte Familienunternehmen wurde 1996 als
                Distributor technischer Kunststoffe gegründet und hat sich über
                die Jahre vom Handelsunternehmen zum Technologie- und
                Entwicklungspartner weiterentwickelt. Seit 2011 ist der
                Firmensitz im nordrhein-westfälischen Overath.
              </p>
              <p class={style.pp_p}>
                Der Fokus des Unternehmens liegt auf dem kundenorientierten
                Zusammenspiel zwischen den Unternehmensbereichen - vom ersten
                Konzept über Material, Design, Prototypen, Vor- und Kleinserien
                bis zur Belieferung der Serie mit hochwertigen
                Kunststoff-Compounds. Alle Leistungen des Unternehmens sind
                sowohl als einzeln, als auch als Komplettpaket verfügbar.
              </p>

              <a
                itemprop="significantLink"
                target="_blank"
                class={style.pp_a}
                href="https://barlog.de"
              >
                ZUR WEBSEITE
              </a>

              <div class={style.pp_dvdr} />

              <div class={style.pp_adr}>
                <p class={style.pp_adr_t}>BARLOG Plastics GmbH</p>
                <p>Am Weidenbach 8-10</p>
                <p>51491 Overath</p>
                <p>Deutschland</p>
              </div>
            </div>

            <div class={style.pp_right}>
              <table class={[style.pp_table + " " + style.pp_mgtop85]}>
                <thead>
                  <tr>
                    {barlogTableDat.headers.map((it) => (
                      <th key={it}>{it}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {barlogTableDat.data.map((it, ind) => (
                    <tr
                      class={
                        (ind + 1) % 2 ? style.pp_table_c1 : style.pp_table_c2
                      }
                      key={it[1]}
                    >
                      {it.map((itt) => (
                        <td key={itt}>{itt}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>

              <div class={style.imgblock}>
                {barlogImagesDat.map((it) => (
                  <ImageMarkup imageConfig={it} />
                ))}
              </div>
            </div>
          </section>

          <section class="headerimage4 nopad">
            <div class={style.pp_fr1024}>
              <div class={[homeStyle.cardouter + " " + homeStyle.inprod]}>
                <div class={homeStyle.cardfr}>
                  <h2>DER SCHNELLSTE WEG ZUR BESTEN BETREUUNG!</h2>
                </div>
                <div class={homeStyle.clfl}>
                  <a href="tel:+410714404444">+41 (0) 71 440 44 44</a>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};

export default BarlogPlastics;
