const barlogTableDat = {
    headers: ['Polymer:', 'Handelsnamen:'],
    data: [
        ['POM', 'KEBAFORM'],
        ['PBT', 'KEBATER'],
        ['PPS', 'KEBATRON'],
        ['LCP', 'KEBAFLOW'],
        ['PEEK, PEK, PEKK', 'KEBAPEAK'],
        ['PC/ABS', 'KEBALLOY'],
        ['Abschirmende Compounds', 'KEBABLEND / RS'],
        ['Elektrisch leitfähige Compounds', 'KEBABLEND / EC'],
        ['Magnetische Compounds', 'KEBABLEND / M und KEBABLEND / MW'],
        ['Compounds für 3D Effektlackierung', 'KEBALLOY 3D'],
        ['Recycling Compounds', 'KEBALLOY ECO'],
        ['Tribocompounds', 'KEBABLEND / WR'],
        ['Wärmeleitfähige Compounds', 'KEBABLEND / TC'],
        ['Funktionalisierte Compounds', 'KEBABLEND'],
    ]
}

export default barlogTableDat;