const swissPlasticExpo2023PreviewImagesDat = {
  alt: "Swiss Plastic Expo, Messe Luzern",
  sizes: "(max-width: 768px) 768px, 768px",
  sources: {
    sfallback: [
      "768",
      "432",
      "/assets/images/news/swissPlasticExpo2023/swiss-plastic-expo-2023-768w.jpg",
    ],
    swebp: [
      [
        "768",
        "/assets/images/news/swissPlasticExpo2023/swiss-plastic-expo-2023-768w.webp",
      ],
    ],
    sjpg: [
      [
        "768",
        "/assets/images/news/swissPlasticExpo2023/swiss-plastic-expo-2023-768w.jpg",
      ],
    ],
  },
};

export default swissPlasticExpo2023PreviewImagesDat;
