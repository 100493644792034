import ImageSmall from "../../../components/imageSmall/ImageSmall";
import style from "../oneNews.scss";
import swissPlasticExpo2023PreviewImagesDat from "./swissPlasticExpo2023PreviewImagesDat";

const SwissPlasticExpo2023 = () => {
  return (
    <>


      <main>
        <div>
          {/** 
      <div class={style.hdr_fr}>
        <h1>News</h1>
        <div class={[style.bgi_lei + " revbgsm"]} />
      </div>
      */}

          <meta itemprop="lastReviewed" content="2022-12-13T08:00:00+08:00" />
          <span itemprop="primaryImageOfPage" itemscope="">
            <link
              itemprop="url"
              href="https://www.hgd-polymers.ch/assets/images/news/swissPlasticExpo2023/16x9/swiss-plastic-expo-1440w.jpg"
            />
          </span>

          <div class={style.os_fr}>
            <div>
              <ImageSmall imageConfig={swissPlasticExpo2023PreviewImagesDat} />
              <meta
                itemprop="image"
                content="https://www.hgd-polymers.ch/assets/images/news/swissPlasticExpo2023/1x1/swiss-plastic-expo-1080w.jpg"
              />
              <meta
                itemprop="image"
                content="https://www.hgd-polymers.ch/assets/images/news/swissPlasticExpo2023/4x3/swiss-plastic-expo-1440w.jpg"
              />
              <meta
                itemprop="image"
                content="https://www.hgd-polymers.ch/assets/images/news/swissPlasticExpo2023/16x9/swiss-plastic-expo-1440w.jpg"
              />
            </div>

            <div class={style.os_txt}>
              <div
                class={style.os_date}
                itemprop="datePublished"
                content="2022-12-13T08:00:00+08:00"
              >
                13. Dezember 2022
              </div>
              <meta
                itemprop="dateModified"
                content="2022-12-13T08:00:00+08:00"
              />
              <h1 itemprop="headline">
                Swiss Plastics Expo - Sie sind unser Gast!
              </h1>
              <section>
                <p>
                  Vom 17. bis 19. Januar 2023 trifft sich die Schweizer
                  Kunststoffindustrie an der{" "}
                  <a
                    itemprop="significantLink"
                    class={style.os_txtlink}
                    href="https://www.swissplastics-expo.ch/de/"
                    target="_blank"
                  >
                    Swiss Plastics Expo
                  </a>
                  . Hier finden Sie alles, was Sie zur erfolgreichen Lancierung
                  Ihrer zukünftigen Projekte brauchen: Inspiration, Fachwissen
                  und die richtigen Partner. Auch wir sind dabei! Die Swiss
                  Plastics Expo bietet Inspiration, Weiterbildung sowie Netzwerk
                  und greift die wichtigsten Themen der Kunststoffindustrie auf.
                  Besuchen Sie uns in der Halle Nr. 2 am Stand Nr. B2043 und
                  erfahren Sie mehr über unsere Produkte und Innovationen.
                  Lassen Sie sich inspirieren.
                </p>

                <p>
                  Mit diesem Code{" "}
                  <span class={style.os_txt_mark}>0104 8894 3655 7209</span>{" "}
                  können Sie unter{" "}
                  <a
                    class={style.os_txtlink}
                    href="https://www.swissplastics-expo.ch"
                    target="_blank"
                  >
                    swissplastics-expo.ch
                  </a>{" "}
                  kostenlos ein Ticket lösen. Wir freuen uns auf Ihren Besuch.
                </p>
              </section>
            </div>
          </div>
        </div>
      </main>

    </>
  );
};

export default SwissPlasticExpo2023;
